import api from '@/utils/api';
import { JOB_PREFERENCE_CATEGORY } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getJobPreferenceCategoryList(context, data) {
        try {
            const response = await api.apiGetAuth(JOB_PREFERENCE_CATEGORY.GET_LIST_PAGINATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createJobPreferenceCategory(context, data) {
        try {
            const response = await api.apiPostAuth(JOB_PREFERENCE_CATEGORY.CREATE_CATEGORY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editJobPreferenceCategory(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(JOB_PREFERENCE_CATEGORY.EDIT_CATEGORY(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async reorderCategoryList(context, data) {
        try {
            const response = await api.apiPatchAuth(JOB_PREFERENCE_CATEGORY.REORDER_LIST, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteJobPreferenceCategory(context, id) {
        try {
            const response = await api.apiDeleteAuth(JOB_PREFERENCE_CATEGORY.DELETE_CATEGORY(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};


export default {
    namespaced,
    actions
};
