// import Vue from 'vue';
import api from '@/utils/api';
import { API_LIVE_STREAM } from '@/utils/v4/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    
};

const mutations = {
    
};

const actions = {
    async createLiveStream(context, data) {
        try {
            const response = await api.apiPostAuth(API_LIVE_STREAM.CREATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async switchStatusLiveStream(context, id) {
        try {
            const response = await api.apiPatchAuth(API_LIVE_STREAM.SWITCH_STATUS(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async editLiveStream(context, data) {
        try {
            const { id, ...formData } = data;
            const response = await api.apiPatchAuth(API_LIVE_STREAM.EDIT(id), formData);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async deleteLiveStream(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_LIVE_STREAM.DELETE(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getLiveStreamListPaginate(context, data) {
        try {
            const response = await api.apiGetAuth(API_LIVE_STREAM.LIST_PAGINATE, data);
            return response;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getLiveStreamDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_LIVE_STREAM.DETAIL(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async liveStreamDownloadReport(context, id) {
        try {
            const response = await api.apiGetAuth(API_LIVE_STREAM.DOWNLOAD_REPORT(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
