import UIkit from 'uikit';
import formatter from './formatter';

const timeout = 5000;
const typeNotif = {
    1: {
        text: 'success',
        icon: 'check'
    },
    2: {
        text: 'danger',
        icon: 'warning'
    }
};

const notificationSuccess = (message) => {
    UIkit.notification({
        message: `<span uk-icon='icon: ${typeNotif[1].icon}'></span> ${message || 'Success'}`,
        status: typeNotif[1].text,
        timeout
    });
};

const notificationDanger = (message) => {
    UIkit.notification({
        message: `<span uk-icon='icon: ${typeNotif[2].icon}'></span> ${formatter.getMessageError(message)}`,
        status: typeNotif[2].text,
        timeout
    });
};

const notificationDangerCustom = (message) => {
    UIkit.notification({
        message: `<span uk-icon='icon: ${typeNotif[2].icon}'></span> ${message || 'Failed'}`,
        status: typeNotif[2].text,
        timeout
    });
};

const closeAllNotification = () => {
    UIkit.notification.closeAll();
};

export {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom,
    closeAllNotification
};
