import axios from 'axios';
import { logoutUser } from '@/utils/auth';
import { notificationDanger, closeAllNotification } from '@/utils/notification';

export default function setup() {
    // Add a response interceptor
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        //catches if the session ended!
        if (error.response && error.response.status === 404) {
            closeAllNotification();
            notificationDanger("Sesi telah berakhir silahkan login kembali");
            setTimeout(() => logoutUser(), 3000);
        }
        return Promise.reject(error);
    });
}
