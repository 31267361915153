import api from '../../../utils/api';
import { API_ADMIN_JOB_VACANCY } from '../../../utils/v4/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {

};

const mutations = {

};

const actions = {
    async createAdminJob(context, data) {
        try {
            const response = await api.apiPostAuth(API_ADMIN_JOB_VACANCY.CREATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async adminJobList(context, data) {
        try {
            const response = await api.apiGetAuth(API_ADMIN_JOB_VACANCY.LIST, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async updateAdminJob(context, data) {
        try {
            const { id, ...formData } = data;
            const response = await api.apiPatchAuth(API_ADMIN_JOB_VACANCY.UPDATE(id), formData);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getAdminJobDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_ADMIN_JOB_VACANCY.GET_BY_ID(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async deleteAdminJob(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_ADMIN_JOB_VACANCY.DELETE(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
