import Vue from 'vue';
import api from '@/utils/api';
import { API_JOB_ADMIN } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    job_admins: {docs:[]},
    job_admin: {},
    job_datas: {
        status: []
    }
};

const mutations = {
    "SET_JOB_ADMINS": (state, payload) => {
        Vue.set(state, 'job_admins', payload);
    },
    "SET_JOB_DATAS": (state, payload) => {
        Vue.set(state, 'job_datas', payload);
    },
    "SET_JOB_ADMIN": (state, payload) => {
        Vue.set(state, 'job_admin', payload);
    }
};

const actions = {
    async getJobData(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB_ADMIN.DATA, data);
            context.commit('SET_JOB_DATAS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJob(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB_ADMIN.LIST, data);
            context.commit('SET_JOB_ADMINS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobDetail(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB_ADMIN.DETAIL(data));
            context.commit('SET_JOB_ADMIN', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createJob(context, data) {
        try {
            await api.apiPostAuth(API_JOB_ADMIN.CREATE, data);
            return true;
        } catch (err) {
            notificationDanger(err);
            return false;
        }
    },
    async updateJob(context, {jobId, data}) {
        try {
            await api.apiPatchAuth(API_JOB_ADMIN.UPDATE(jobId), data);
            return true;
        } catch (err) {
            notificationDanger(err);
            return false;
        }
    },
};

const getters = {
    job_admins: state => {
        return state.job_admins;
    },
    job_datas: state => {
        return state.job_datas;
    },
    job_admin: state => {
        return state.job_admin;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
