// import Vue from 'vue';
import api from '@/utils/api';
import { API_LIVE_STREAM_CHAT } from '@/utils/v4/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {};

const mutations = {};

const actions = {
    async getLiveStreamChatListPaginate(context, data) {
        try {
            const response = await api.apiGetAuth(API_LIVE_STREAM_CHAT.LIST_PAGINATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getUserOptions(context, data) {
        try {
            const response = await api.apiGetAuth(API_LIVE_STREAM_CHAT.USER_OPTIONS, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
