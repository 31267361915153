import api from '@/utils/api';
import {API_AUTH} from '@/utils/api-url';
import {getUserLogin, logoutUser, setAuthCookie, setUserRole} from '@/utils/auth';
import {notificationDanger, notificationSuccess} from '@/utils/notification';
import router from '@/router';

const namespaced = true;
const state = {
    isLogin: getUserLogin() !== null,
};

const actions = {
    async login(context, data) {
        try {
            return await api.apiPostNonAuth(API_AUTH.LOGIN, data);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async setLoginCredential(context, payload) {
        try {
            const { data } = await api.apiPostNonAuth(API_AUTH.VALIDATE_SECURE_AUTH, { key: payload.key });
            if (data.status === 'OK') {
                setAuthCookie(payload.auth.token);
                setUserRole(payload.auth.result.roles);
                notificationSuccess('Login Success');
                setTimeout(() => router.go(0), 1000);
            } else {
                notificationDanger('Invalid key');
            }

        } catch (err) {
            notificationDanger(err);
        }
    },
    async logout() {
        try {
            await api.apiGetAuth(API_AUTH.LOGOUT);
            logoutUser();
            notificationSuccess('Logout Success');
        } catch (err) {
            notificationDanger(err);
        }
    },
};


export default {
    namespaced,
    state,
    actions,
};
