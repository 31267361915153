import { getUserLogin } from '@/utils/auth';
import moment from 'moment';
moment.locale('id');
import { DateTime } from 'luxon';

const formatter = {};
formatter.getFullname = (user) => {
    const data = user || getUserLogin();
    return data.fullname || 'n/a';
};

formatter.selectData = (data, option) => {
    if (data === null) return 'n/a';

    const select = option.find(x => x.value === data.toLowerCase());
    return select ? select.name : 'n/a';
};

formatter.textAvatar = () => {
    const data = formatter.getFullname();
    if (data) {
        const textArr = data.split(" ");
        if (textArr.length > 1) {
            return `${textArr[0].substring(0, 1).toUpperCase()}${textArr[1].substring(0, 1).toUpperCase()}`;
        }
        return data.substring(0, 2).toUpperCase();
    }
    return '';
};

formatter.getMessageError = err => {
    let message = null;
    if (err.response) {
        message =
            typeof err.response === 'object'
                ? typeof err.response.data === 'object'
                    ? err.response.data.message
                    : null
                : null;
    } else if (err.message) {
        message = err.message;
    } else {
        message = err;
    }

    return message || 'Something wrong';
};

formatter.getHeaderTitle = (fullpath) => {
    if (fullpath.search('dashboard') >= 0) {
        return 'Dashboard';
    } else if (fullpath.search('marketing/post') >= 0) {
        return 'Posts Report';
    } else if (fullpath.search('report/user') >= 0) {
        return 'User Report';
    } else if (fullpath.search('report/mitra') >= 0) {
        return 'Mitra Report';
    } else if (fullpath.search('report/company_attendance_tracker') >= 0) {
        return 'Company Attendance Tracker Report';
    } else if (fullpath.search('report/company') >= 0) {
        return 'Company Report';
    } else if (fullpath.search('report/entry') >= 0) {
        return 'Entry User Report';
    } else if (fullpath.search('job-preference') >= 0) {
        return 'Job Preference';
    } else if (fullpath.search('applicant/whatsapp-template') >= 0) {
        return 'Applicant - WhatsApp Template';
    } else if (fullpath.search('job-combine') >= 0) {
        return 'Job Combine';
    } else if (fullpath.search('job-admin') >= 0) {
        return 'Job Admin';
    } else if (fullpath.search('contract') >= 0) {
        return 'Contract';
    } else if (fullpath.search('hospitality/category') >= 0) {
        return 'Hospitality / Category';
    } else if (fullpath.search('mitra/user-attendance') >= 0) {
        return 'User Attendance';
    } else if (fullpath.search('user-employer') >= 0) {
        return 'Employer';
    } else if (fullpath.search('all-user') >= 0) {
        return 'All User';
    } else if (fullpath.search('candidate/detail') >= 0) {
        return 'Candidate Detail';
    } else if (fullpath.search('candidate') >= 0) {
        return 'Candidate';
    } else if (fullpath.search('administrator') >= 0) {
        return 'Administrator';
    } else if (fullpath.search('cv-classification') >= 0) {
        return 'CV Classification';
    } else if (fullpath.search('post') >= 0) {
        return 'Post';
    } else if (fullpath.search('mitra/company/workers_request') >= 0) {
        return 'Workers Request';
    } else if (fullpath.search('worker') >= 0) {
        return 'PWA Worker';
    } else if (fullpath.search('employer') >= 0) {
        return 'PWA Employer';
    } else if (fullpath.search('test-category') >= 0) {
        return 'Test Category';
    } else if (fullpath.search('test-question') >= 0) {
        return 'Test Question';
    } else if (fullpath.search('mitra/company-shift') >= 0) {
        return 'Company Shift';
    } else if (fullpath.search('mitra/workforce-assignment') >= 0) {
        return 'Workers Assignment';
    } else if (fullpath.search('mitra/company-role') >= 0) {
        return 'Company Role';
    } else if (fullpath.search('mitra/company/detail') >= 0) {
        return 'Company Detail';
    } else if (fullpath.search('mitra/company/attendance') >= 0) {
        return 'Office Attendance';
    } else if (fullpath.search('mitra/company/role_salary') >= 0) {
        return 'Salary Setup (Property-Role Setting)';
    } else if (fullpath.search('mitra/company/assign_mitra') >= 0) {
        return 'Assign Mitra';
    } else if (fullpath.search('mitra/company/office_type') >= 0) {
        return 'Office Type';
    } else if (fullpath.search('mitra/property-role-setting') >= 0) {
        return 'Salary Setup (Property-Role Setting)';
    } else if (fullpath.search('mitra/company/setting/overtime-report-view') >= 0) {
        return 'Setting - Overtime Report View';
    } else if (fullpath.search('mitra/company') >= 0) {
        return 'Company';
    } else if (fullpath.search('mitra/property-type') >= 0) {
        return 'Property Type';
    } else if (fullpath.search('mitra/property') >= 0) {
        return 'Property';
    } else if (fullpath.search('mitra/job-partner') >= 0) {
        return 'Job Partner';
    } else if (fullpath.search('mitra/daily-recap') >= 0) {
        return 'Daily Recap';
    } else if (fullpath.search('mitra/schedule') >= 0) {
        return 'Schedule';
    } else if (fullpath.search('mitra') >= 0) {
        return 'Mitra';
    } else if (fullpath.search('kormo/seekers') >= 0) {
        return 'Kormo Seekers';
    } else if (fullpath.search('marketing/banner') >= 0) {
        return 'Banner';
    } else if (fullpath.search('marketing/testimonial') >= 0) {
        return 'Testimonial';
    } else if (fullpath.search('community/community/topic') >= 0) {
        return 'Community Topic';
    } else if (fullpath.search('marketing/social_media') >= 0) {
        return 'Social Media Myrobin';
    } else if (fullpath.search('community/live-content') >= 0) {
        return 'Live Content';
    } else if (fullpath.search('community/community') >= 0) {
        return 'Community';
    } else if (fullpath.search('community/coupon') >= 0) {
        return 'Voucher';
    } else if (fullpath.search('marketing/jobs/detail') >= 0) {
        return 'Job Detail';
    } else if (fullpath.search('marketing/silver_package') >= 0) {
        return 'Silver Package';
    } else if (fullpath.search('platform-version') >= 0) {
        return 'Version';
    } else if (fullpath.search('whats-new') >= 0) {
        return "What's New?";
    } else if (fullpath.search('community/mywarung') >= 0) {
        return 'MyWarung';
    } else if (fullpath.search('community/ewa') >= 0) {
        return 'Early Wage Access';
    } else if (fullpath.search('additional-document') >= 0) {
        return 'Additional Documents';
    } else if (fullpath.search('additional-questions') >= 0) {
        return 'Additional Questions';
    } else if (fullpath.search('membership/detail') >= 0) {
        return 'Paid Membership Detail';
    } else if (fullpath.search('membership') >= 0) {
        return 'Premium Membership';
    } else if (fullpath.search('vacancy-reference') >= 0) {
        return 'Vacancy Reference';
    } else if (fullpath.search('admin-job-vacancy') >= 0) {
        return 'Administrator Job Vacancy (WhatsApp)';
    } else {
        return 'Job';
    }
};

formatter.dateComplete = date => {
    // 25 Nov 2019
    return moment(date).format('DD MMM YYYY');
};

formatter.dateComplete2 = date => {
    // 25 November 2019
    return moment(date).format('DD MMMM YYYY');
};

formatter.datetimeComplete = date => {
    // 25 November 2019 17:31:21
    return moment(date).format('DD MMMM YYYY HH:mm:ss');
};

formatter.datetimeShort = date => {
    // 25/11/2019 17:31:21
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

formatter.thousandSeparator = data => {
    return Math.abs(data || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

formatter.numberFormat = value => {
    return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
        minimumFractionDigits: 0,
    })
        .formatToParts(value)
        .filter(p => p.type != 'currency')
        .reduce((s, p) => s + p.value, 'Rp ')
        .trim();
};

formatter.ageInYear = date => {
    // 25
    return moment().diff(date, 'years');
};

formatter.monthTotal = date => {
    return moment().diff(date, 'months');
};

formatter.getMonthYear = date => {
    moment().locale('id');
    return moment(date).format("MMM YYYY");
};

formatter.salaryCurrency = (data) => {
    const salary = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    }).format(data);
    return salary;
};

formatter.getYearOnly = date => {
    moment().locale('id');
    if (date) {
        return moment(date).format("YYYY");
    } else {
        return moment().format("YYYY");
    }
};

formatter.isSameDate = (firstDate, secondDate) => {
    return moment(firstDate).isSame(secondDate);
};

formatter.replaceHtmlTag = (string) => {
    return string.replace(/<\/?[a-z][^>]*>/ig, ' ');
};

formatter.autoIncrementNumberPagination = ({ pagingCounter, index }) => {
    if (!isNaN(pagingCounter) && !isNaN(index)) {
        return Number.parseInt(pagingCounter) + Number.parseInt(index);
    } else {
        return '-';
    }
};

formatter.dateShort = (date) => {
    // 2022-01-31
    return moment(date).format('YYYY-MM-DD');
};

formatter.hourMinuteFromObject = (data) => {
    // 01:30
    return DateTime.fromObject(data).toLocaleString(DateTime.TIME_24_SIMPLE);
};

formatter.hourMinuteFromDate = (date) => {
    // 01.30
    return DateTime.fromISO(date).toFormat('hh.mm');
};

formatter.dateSubstract = ({ date, amount, unit }) => {
    return moment(date).subtract(amount, unit);
};

formatter.dateAdd = ({ date, amount, unit }) => {
    return moment(date).add(amount, unit);
};

formatter.hasSameDate = ({ firstDate, secondDate }) => {
    const date1 = formatter.dateShort(firstDate);
    const date2 = formatter.dateShort(secondDate);
    return new Date(date1).getTime() === new Date(date2).getTime();
};

formatter.startOfDay = (date) => {
    return moment(date).startOf('day').toLocaleString();
};

formatter.endOfDay = (date) => {
    return moment(date).endOf('day').toLocaleString();
};

formatter.replaceCodeCountryPhoneNumber = ({ text }) => {
    let phone = '';
    phone = text === null ? phone : text.replace('+62','0');
    return phone;
};

formatter.arrayPreview = ({ data, limit }) => {
    if (!Array.isArray(data)) throw new Error('Data must be a array');
    if (typeof limit !== "number") throw new Error('Limit must be a number');

    let label = '';
    if (data.length < limit) {
        data.forEach((element) => {
            if (label.length < 1) {
                label += element.value || '-';
            } else {
                label += `, ${element.value || '-'}`;
            }
        });
    } else {
        data.forEach((element, index) => {
            if (index < limit) {
                if (label.length < 1) {
                    label += element.value || '-';
                } else {
                    label += `, ${element.value || '-'}`;
                }
            } else if (index == limit) {
                label += '...';
            }
        });
    }

    return label;
};

formatter.dateWithSlash = (date) => {
    return moment(date).format('DD/MM/YYYY');
};

export default formatter;
