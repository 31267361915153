import Vue from 'vue';
import api from '@/utils/api';
import { API_USER } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    users: {docs:[]},
    candidates: {docs:[]},
    employers: {docs:[]}
};

const mutations = {
    "SET_USER": (state, payload) => {
        Vue.set(state, 'users', payload);
    },
    "SET_CANDIDATE": (state, payload) => {
        Vue.set(state, 'candidates', payload);
    },
    "SET_EMPLOYER": (state, payload) => {
        Vue.set(state, 'employers', payload);
    }
};

const actions = {
    async getUser(context, data) {
        try {
            const response = await api.apiGetAuth(API_USER.CREATE_LIST, data);
            context.commit('SET_USER', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserCandidate(context, data) {
        try {
            const response = await api.apiGetAuth(API_USER.LIST_CANDIDATE, data);
            context.commit('SET_USER', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserPartner(context, data) {
        try {
            const response = await api.apiGetAuth(API_USER.CREATE_LIST_PARTNER, data);
            context.commit('SET_USER', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_USER.DETAIL(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async ListsUser(context, {roles, scope}) {
        try {
            const response = await api.apiGetAuth(API_USER.LISTS(roles, scope));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },

    async getReportUser(context, data) {
        try {
            const response = await api.apiGetAuth(API_USER.REPORT_USER, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },

    async createUser(context, data) {
        try {
            const response = await api.apiPostAuth(API_USER.CREATE_LIST, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateUser(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_USER.UPDATE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteUser(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_USER.UPDATE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async bulkUploadCandidate(context, data) {
        try {
            const response = await api.apiPostAuth(API_USER.BULK_UPLOAD_CANDIDATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCandidate(context, data) {
        try {
            const response = await api.apiGetAuth(API_USER.CANDIDATE, data);
            context.commit('SET_CANDIDATE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEmployer(context, data) {
        try {
            const response = await api.apiGetAuth(API_USER.EMPLOYER, data);
            context.commit('SET_EMPLOYER', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addPartner(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_USER.ADD_DELETE_PARTNER(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deletePartner(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiDeleteAuth(API_USER.ADD_DELETE_PARTNER(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createUserEducation(context, data) {
        try {
            const response = await api.apiPostAuth(API_USER.CREATE_EDUCATION, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateUserEducation(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(API_USER.UPDATE_DELETE_EDUCATION(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteUserEducation(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_USER.UPDATE_DELETE_EDUCATION(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createUserWorkExp(context, data) {
        try {
            const response = await api.apiPostAuth(API_USER.CREATE_WORK_EXP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateUserWorkExp(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(API_USER.UPDATE_DELETE_WORK_EXP(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteUserWorkExp(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_USER.UPDATE_DELETE_WORK_EXP(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createUserSkill(context, data) {
        try {
            const response = await api.apiPostAuth(API_USER.CREATE_SKILL, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async userUploadOtherDocument(context, { folder, user_id, data }) {
        try {
            const response = await api.apiPostAuth(API_USER.UPLOAD_OTHER_DOCUMENT(folder, user_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async userUploadDocument(context, { folder, type, user_id, data }) {
        try {
            const response = await api.apiPostAuth(API_USER.UPLOAD_DOCUMENT(folder, type, user_id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteUserOtherDocument(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_USER.DELETE_OTHER_DOCUMENT(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    users: state => {
        return state.users;
    },
    candidates: state => {
        return state.candidates;
    },
    employers: state => {
        return state.employers;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
