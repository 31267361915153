import Vue from 'vue';
import api from '@/utils/api';
import { API_HOSPITALITY } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    categories: {docs:[]},
    jobs: {docs:[]}
};

const mutations = {
    "SET_CATEGORIES": (state, payload) => {
        Vue.set(state, 'categories', payload);
    },
    "SET_JOBS": (state, payload) => {
        Vue.set(state, 'jobs', payload);
    }
};

const actions = {
    async getCategory(context, data) {
        try {
            const response = await api.apiGetAuth(API_HOSPITALITY.CATEGORY_LIST, data);
            context.commit('SET_CATEGORIES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllCategory(context, data) {
        try {
            const response = await api.apiGetAuth(API_HOSPITALITY.CATEGORY_LIST_UNPAGINATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCategory(context, data) {
        try {
            const response = await api.apiPostAuth(API_HOSPITALITY.CREATE_CATEGORY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJob(context, {seo_url, data}) {
        try {
            const response = await api.apiGetAuth(API_HOSPITALITY.JOB_LIST(seo_url), data);
            context.commit('SET_JOBS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createJob(context, data) {
        try {
            const response = await api.apiPostAuth(API_HOSPITALITY.CREATE_JOB, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {
    categories: state => {
        return state.categories;
    },
    jobs: state => {
        return state.jobs;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
