import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_SCHEDULE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    schedules: {docs:[]},
    schedulesCalendar: {docs:[]}
};

const mutations = {
    "SET_SCHEDULES": (state, payload) => {
        Vue.set(state, 'schedules', payload);
    },
    "SET_SCHEDULES_CALENDAR": (state, payload) => {
        Vue.set(state, 'schedulesCalendar', payload);
    }
};

const actions = {
    async getSchedules(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SCHEDULE.CREATE_RETRIEVE, data);
            context.commit('SET_SCHEDULES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSchedulesinCalendar(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SCHEDULE.CREATE_RETRIEVE, data);
            context.commit('SET_SCHEDULES_CALENDAR', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserPartner(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SCHEDULE.GET_USER_PARTNER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllCompanyShift(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SCHEDULE.GET_ALL_COMPANY_SHIFT + '/' + data.companyOfficeId, '');
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async checkAvailable(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SCHEDULE.CHECK_AVAILABLE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postCreateSchedule(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_SCHEDULE.CREATE_RETRIEVE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteScheduleShift(context, data) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_SCHEDULE.DELETE + '/' + data.id, {company_id: data.company_id});
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postNotifSchedule(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_SCHEDULE.CREATE_RETRIEVE + '/' + data.id + '/send-notif?date=' + data.date, {company_id: data.company_id});
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showScheduleShift(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SCHEDULE.CREATE_RETRIEVE + '/' + data.id + '?company_id=' + data.company_id);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async putEditSchedule(context, data) {
        try {
            const response = await api.apiPutAuth(API_MITRA_SCHEDULE.CREATE_RETRIEVE + '/' + data.id, data.payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteSpecificScheduleShift(context, data) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_SCHEDULE.DELETE + '/' + data.id + '/specific?date=' + data.date);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    schedules: state => {
        return state.schedules;
    },
    schedulesCalendar: state => {
        return state.schedulesCalendar;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
