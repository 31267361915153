import api from '@/utils/api';
import { ADDITIONAL_QUESTION_TEMPLATE } from '@/utils/v4/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const state = {};

const mutations = {};

const actions = {
    async createTemplate(context, data) {
        try {
            const { categoryId, ...formData } = data;
            const response = await api.apiPostAuth(ADDITIONAL_QUESTION_TEMPLATE.CREATE(categoryId), formData);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getTemplateList(context, data) {
        try {
            const { categoryId, ...meta } = data;
            const response = await api.apiGetAuth(ADDITIONAL_QUESTION_TEMPLATE.LIST(categoryId), meta);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async editTemplate(context, data) {
        try {
            const { categoryId, id, ...formData } = data;
            const response = await api.apiPatchAuth(ADDITIONAL_QUESTION_TEMPLATE.EDIT(categoryId, id), formData);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async deleteTemplate(context, data) {
        try {
            const { categoryId, id } = data;
            const response = await api.apiDeleteAuth(ADDITIONAL_QUESTION_TEMPLATE.DELETE(categoryId, id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
