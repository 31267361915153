import api from '@/utils/api';
import { API_APPLICANT } from '@/utils/v4/api-url';
import { notificationDanger } from '@/utils/notification';

const namespaced = true;
const state = {

};

const mutations = {

};

const actions = {
    async getWhatsAppTemplateList(context, data) {
        try {
            const response = await api.apiGetAuth(API_APPLICANT.LIST_WHATSAPP_TEMPLATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createWhatsAppTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(API_APPLICANT.CREATE_WHATSAPP_TEMPLATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateWhatsAppTemplate(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_APPLICANT.UPDATE_WHATSAPP_TEMPLATE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteWhatsAppTemplate(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_APPLICANT.DELETE_WHATSAPP_TEMPLATE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};


const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};

