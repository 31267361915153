import api from '@/utils/api';
import { API_OTHER_DOCUMENT } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getAdditionalDocument(context, payload) {
        try {
            const response = await api.apiGetAuth(API_OTHER_DOCUMENT.LIST_DOCUMENT, payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteAdditionalDocument(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_OTHER_DOCUMENT.DELETE_DOCUMENT(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchAdditionalDocument(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiPatchAuth(API_OTHER_DOCUMENT.PATCH_DOCUMENT(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createAdditionalDocument(context, payload) {
        try {
            const response = await api.apiPostAuth(API_OTHER_DOCUMENT.CREATE_DOCUMENT, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
