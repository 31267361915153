import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_PROPERTY } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    properties: {docs:[]},
    property: {},
    office_logs: {docs:[]},
};

const mutations = {
    "SET_PROPERTIES": (state, payload) => {
        Vue.set(state, 'properties', payload);
    },
    "SET_COMPANY": (state, payload) => {
        Vue.set(state, 'property', payload);
    },
    "SET_OFFICE_LOG": (state, payload) => {
        Vue.set(state, 'office_logs', payload);
    },
};

const actions = {
    async getProperties(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY.CREATE_RETRIEVE, data);
            context.commit('SET_PROPERTIES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getPropertyDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_COMPANY', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createProperty(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_PROPERTY.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateProperty(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_PROPERTY.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteProperty(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_PROPERTY.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyOfficeLog(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY.RETRIEVE_LOGS, data);
            context.commit('SET_OFFICE_LOG', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    properties: state => {
        return state.properties;
    },
    property: state => {
        return state.property;
    },
    office_logs: state => {
        return state.office_logs;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
