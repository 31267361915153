import api from '../../../utils/api';
import {API_VACANCY_REFERENCE} from '../../../utils/v4/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {

};

const mutations = {

};

const actions = {
    async createVacancyRef(context, data) {
        try {
            const response = await api.apiPostAuth(API_VACANCY_REFERENCE.CREATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getVacancyReferenceList(context, data){
        try {
            const response = await api.apiGetAuth(API_VACANCY_REFERENCE.LIST_PAGINATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async updateVacancyReference(context, data){
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_VACANCY_REFERENCE.UPDATE(id), formData);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getVacancyReferenceDetail(context, id){
        try {
            const response = await api.apiGetAuth(API_VACANCY_REFERENCE.GET_BY_ID(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async deleteVacancyReference(context, id){
        try {
            const response = await api.apiDeleteAuth(API_VACANCY_REFERENCE.DELETE(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async reorderVacancyRefList(context, data) {
        try {
            const response = await api.apiPatchAuth(API_VACANCY_REFERENCE.REORDER, data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
