import api from '@/utils/api';
import { API_MEMBERSHIP } from '@/utils/v4/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {

};

const mutations = {

};

const actions = {
    async getMembershipList(context, data) {
        try {
            const response = await api.apiGetAuth(API_MEMBERSHIP.LIST_MEMBERSHIP, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getMembershipDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MEMBERSHIP.DETAIL(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getMembershipDetailList(context, userId, data) {
        try {
            const response = await api.apiGetAuth(API_MEMBERSHIP.DETAIL_LIST(userId), data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async downloadMembershipTemplate(context, payload) {
        try {
            const response = await api.apiPostAuth(API_MEMBERSHIP.DOWNLOAD_TEMPLATE, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadMembershipTemplate(context, payload) {
        try {
            const response = await api.apiPostAuth(API_MEMBERSHIP.UPLOAD_TEMPLATE, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
