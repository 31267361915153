import Vue from 'vue';
import api from '@/utils/api';
import { API_APPLICANT } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    applicants: {docs:[]}
};

const mutations = {
    "SET_APPLICANTS": (state, payload) => {
        Vue.set(state, 'applicants', payload);
    }
};

const actions = {
    async getApplicant(context, {job_id, data}) {
        try {
            const response = await api.apiGetAuth(API_APPLICANT.LIST(job_id), data);
            context.commit('SET_APPLICANTS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async setApplicantScore(context, {applicant_id, score}) {
        try {
            const response = await api.apiPatchAuth(API_APPLICANT.SET_SCORE(applicant_id), {score: score});
            return response.data.status;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {
    applicants: state => {
        return state.applicants;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
