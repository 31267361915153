import api from '@/utils/api';
import {
    API_COMMUNITY
} from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    // Coupon
    async showCoupon(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_DETAIL_COUPON(data.coupon_id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCoupon(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_COUPON, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCoupon(context, data) {
        try {
            const response = await api.apiPostAuth(API_COMMUNITY.LIST_COUPON, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteCoupon(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_COMMUNITY.DELETE_COUPON(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateCoupon(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMMUNITY.UPDATE_COUPON(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadTemplateVoucher(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.DOWNLOAD_TEMPLATE_VOUCHER(data));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadTemplateVoucher(context, data) {
        try {
            console.log(data);
            const response = await api.apiPostAuth(API_COMMUNITY.UPLOAD_TEMPLATE_VOUCHER(data.get('id')), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // EWA
    async showEWA(context, id) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.DETAIL_EWA(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEWA(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_EWA, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async acceptEWA(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMMUNITY.ACCEPT_EWA(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async rejectEWA(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMMUNITY.REJECT_EWA(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // EWA Vendor
    async showEWAVendor(context, id) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.SPECIFIC_EWA_VENDOR(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEWAVendor(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_EWA_VENDOR, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createEWAVendor(context, data) {
        try {
            const response = await api.apiPostAuth(API_COMMUNITY.LIST_EWA_VENDOR, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editEWAVendor(context, data) {
        try {
            const {_id, ...formData} = data;
            const response = await api.apiPutAuth(API_COMMUNITY.SPECIFIC_EWA_VENDOR(_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteEWAVendor(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_COMMUNITY.SPECIFIC_EWA_VENDOR(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // Group
    async getMemberGroup(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_MEMBER_GROUP(data.group_id), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGroup(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_GROUP, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async listGroup(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_GROUP_NON_PAGINATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showGroup(context, id) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.SPECIFIC_GROUP(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteGroup(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_COMMUNITY.SPECIFIC_GROUP(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteMemberGroup(context, payload) {
        try {
            const response = await api.apiDeleteAuth(API_COMMUNITY.SPECIFIC_MEMBER_GROUP(payload));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async bannedMemberGroup(context, payload) {
        try {
            const response = await api.apiPostAuth(API_COMMUNITY.BANNED_SPECIFIC_MEMBER, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async moveMemberGroup(context, payload) {
        try {
            const response = await api.apiPatchAuth(API_COMMUNITY.MOVE_SPECIFIC_MEMBER, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createGroup(context, data) {
        try {
            const response = await api.apiPostAuth(API_COMMUNITY.LIST_GROUP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateGroup(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMMUNITY.SPECIFIC_GROUP(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // Community
    async getCommunity(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_COMMUNITY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showCommunity(context, id) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.SPECIFIC_COMMUNITY(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteCommunity(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_COMMUNITY.SPECIFIC_COMMUNITY(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCommunity(context, data) {
        try {
            const response = await api.apiPostAuth(API_COMMUNITY.LIST_COMMUNITY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateCommunity(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMMUNITY.SPECIFIC_COMMUNITY(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    // Topic
    async getTopic(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.LIST_TOPIC, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showTopic(context, id) {
        try {
            const response = await api.apiGetAuth(API_COMMUNITY.SPECIFIC_TOPIC(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteTopic(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_COMMUNITY.SPECIFIC_TOPIC(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createTopic(context, data) {
        try {
            const response = await api.apiPostAuth(API_COMMUNITY.LIST_TOPIC, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateTopic(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMMUNITY.SPECIFIC_TOPIC(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
