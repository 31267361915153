import api from '@/utils/api';
import { ADDITIONAL_QUESTION_TEMPLATE_CATEGORY } from '@/utils/v4/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const state = {};

const mutations = {};

const actions = {
    async createTemplateCategory(context, data) {
        try {
            const response = await api.apiPostAuth(ADDITIONAL_QUESTION_TEMPLATE_CATEGORY.CREATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getTemplateCategoryListPaginate(content, data) {
        try {
            const response = await api.apiGetAuth(ADDITIONAL_QUESTION_TEMPLATE_CATEGORY.LIST_PAGINATE, data);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async editTemplateCategory(context, data) {
        try {
            const { id, ...formData } = data;
            const response = await api.apiPatchAuth(ADDITIONAL_QUESTION_TEMPLATE_CATEGORY.EDIT(id), formData);
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async deleteTemplateCategory(context, id) {
        try {
            const response = await api.apiDeleteAuth(ADDITIONAL_QUESTION_TEMPLATE_CATEGORY.DELETE(id));
            return response;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
