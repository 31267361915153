import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_PROPERTY_TYPE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    property_types: {docs:[]},
    property_type: {},
};

const mutations = {
    "SET_PROPERTY_TYPES": (state, payload) => {
        Vue.set(state, 'property_types', payload);
    },
    "SET_PROPERTY_TYPE": (state, payload) => {
        Vue.set(state, 'property_type', payload);
    },
};

const actions = {
    async getPropertyTypes(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY_TYPE.CREATE_RETRIEVE, data);
            context.commit('SET_PROPERTY_TYPES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getPropertyTypeDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_PROPERTY_TYPE.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_PROPERTY_TYPE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createPropertyType(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_PROPERTY_TYPE.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updatePropertyType(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_PROPERTY_TYPE.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deletePropertyType(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_PROPERTY_TYPE.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadOfficeTypeTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_PROPERTY_TYPE.UPLOAD_OFFICE_TYPE_TEMPLATE(data.get('company_id')), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    property_types: state => {
        return state.property_types;
    },
    property_type: state => {
        return state.property_type;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
