import Vue from 'vue';
import api from '@/utils/api';
import { INSTAJOB_API_POST, INSTAJOB_API_FILE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    posts: {docs:[]}
};

const mutations = {
    "SET_POSTS": (state, payload) => {
        Vue.set(state, 'posts', payload);
    }
};

const actions = {
    async getPost(context, data) {
        try {
            const response = await api.apiGetAuth(INSTAJOB_API_POST.ALL, data);
            context.commit('SET_POSTS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadImage(context, { folder, data }) {
        try {
            const response = await api.apiPostAuth(
                INSTAJOB_API_FILE.IMAGE(folder),
                data
            );
            return response.data.result;
        } catch (err) {
            console.error(err);
        }
    },
    async createPost(context, data) {
        try {
            const response = await api.apiPostAuth(INSTAJOB_API_POST.CREATE, data);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    }
};

const getters = {
    posts: state => {
        return state.posts;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
