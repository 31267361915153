import api from '@/utils/api';
import { CONTRACT } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getContractsPaginate(context, data) {
        try {
            const response = await api.apiGetAuth(CONTRACT.LIST_CREATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getContractDetail(context, id) {
        try {
            const response = await api.apiGetAuth(CONTRACT.UPDATE_DETAIL(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createContract(context, data) {
        try {
            const response = await api.apiPostAuth(CONTRACT.LIST_CREATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateContract(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(CONTRACT.UPDATE_DETAIL(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadImgFounderSignature(context, data) {
        try {
            const response = await api.apiPostAuth(CONTRACT.FOUNDER_SIGNATURE, data);
            return response.data;
        } catch (err) {
            console.error(err);
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
