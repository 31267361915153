import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_SHIFT } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    shifts: {docs:[]},
    shift: {},
};

const mutations = {
    "SET_SHIFTS": (state, payload) => {
        Vue.set(state, 'shifts', payload);
    },
    "SET_SHIFT": (state, payload) => {
        Vue.set(state, 'shift', payload);
    },
};

const actions = {
    async getShifts(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SHIFT.CREATE_RETRIEVE, data);
            context.commit('SET_SHIFTS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShiftDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_SHIFT.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_SHIFT', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createShift(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_SHIFT.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateShift(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_SHIFT.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteShift(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_SHIFT.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    shifts: state => {
        return state.shifts;
    },
    shift: state => {
        return state.shift;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
