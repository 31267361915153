import api from '@/utils/api';
import { API_DASHBOARD } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getTotalUser(context, data) {
        try {
            const response = await api.apiGetAuth(API_DASHBOARD.RETRIEVE_TOTAL_USER, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getTotalNewUser(context, data) {
        try {
            const response = await api.apiGetAuth(API_DASHBOARD.RETRIEVE_TOTAL_NEW_USER, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getNewCandidate(context, data) {
        try {
            const response = await api.apiGetAuth(API_DASHBOARD.RETRIEVE_NEW_CANDIDATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraOnCity(context, data) {
        try {
            const response = await api.apiGetAuth(API_DASHBOARD.RETRIEVE_MITRA_ON_CITY, data);
            return response.data.mitra_per_city;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCandidateOnCity(context, data) {
        try {
            const response = await api.apiGetAuth(API_DASHBOARD.RETRIEVE_CANDIDATE_ON_CITY, data);
            return response.data.candidate_per_city;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCandidateOnNewPWA(context, data) {
        try {
            const response = await api.apiGetAuth(API_DASHBOARD.RETRIEVE_CANDIDATE_ON_NEW_PWA, data);
            return response.data.newPWA_data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
