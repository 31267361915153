import Vue from 'vue';
import api from '@/utils/api';
import { PLATFORM_CATEGORY, PLATFORM_VERSION } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const state = {
    latest_version_data: null
};

const mutations = {
    "SET_LATEST_VERSION_DATA": (state, payload) => {
        Vue.set(state, 'latest_version_data', payload);
    }
};

const actions = {
    // Platform category
    async getAllPlatformCategory(context, data) {
        try {
            const response = await api.apiGetAuth(PLATFORM_CATEGORY.ALL_LIST, data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },

    // Platform version
    async getPaginateListPlatformVersion(context, data) {
        try {
            const response = await api.apiGetAuth(PLATFORM_VERSION.PAGINATE_LIST, data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getLatestVersion(context, data) {
        try {
            const response = await api.apiGetAuth(PLATFORM_VERSION.LATEST_VERSION, data);
            context.commit('SET_LATEST_VERSION_DATA', response.data.result);
        } catch (error) {
            notificationDanger(error);
        }
    },
    async createPlatformVersion(context, data) {
        try {
            const response = await api.apiPostAuth(PLATFORM_VERSION.CREATE, data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async editPlatformVersion(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(PLATFORM_VERSION.EDIT(id), formData);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async deletePlatformVersion(context, id) {
        try {
            const response = await api.apiDeleteAuth(PLATFORM_VERSION.DELETE(id));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    }
};

const getters = {
    latest_version_data: state => {
        return state.latest_version_data;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
