import api from '@/utils/api';
import { API_FILE } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async uploadImage(context, { folder, category, data }) {
        try {
            const response = await api.apiPostAuth(
                API_FILE.IMAGE(folder, category),
                data
            );
            return response.data.result;
        } catch (err) {
            console.error(err);
            notificationDanger(err);
        }
    },
    async uploadDocument(context, { folder, category, data }) {
        try {
            const response = await api.apiPostAuth(
                API_FILE.DOCUMENT(folder, category),
                data
            );
            return response.data.result;
        } catch (err) {
            console.error(err);
            notificationDanger(err);
        }
    },
    async uploadFile(context, { folder, category, data }) {
        try {
            const response = await api.apiPostAuth(
                API_FILE.FILE(folder, category),
                data
            );
            return response.data.result;
        } catch (err) {
            console.error(err);
            notificationDanger(err);
        }
    },
    async uploadImagePDF(context, { folder, category, data }) {
        try {
            const response = await api.apiPostAuth(
                API_FILE.FILE(folder, category),
                data
            );
            return response.data;
        } catch (err) {
            console.error(err);
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
