import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_JOB_PARTNER } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    job_partners: {docs:[]},
    job_partner: {},
};

const mutations = {
    "SET_JOB_PARTNERS": (state, payload) => {
        Vue.set(state, 'job_partners', payload);
    },
    "SET_JOB_PARTNER": (state, payload) => {
        Vue.set(state, 'job_partner', payload);
    }
};

const actions = {
    async getJobPartners(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_JOB_PARTNER.CREATE_RETRIEVE, data);
            context.commit('SET_JOB_PARTNERS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobPartnerDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_JOB_PARTNER.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_JOB_PARTNER', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createJobPartner(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_JOB_PARTNER.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateJobPartner(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_JOB_PARTNER.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteJobPartner(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_JOB_PARTNER.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    job_partners: state => {
        return state.job_partners;
    },
    job_partner: state => {
        return state.job_partner;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
