import api from '@/utils/api';
import { API_INTERNAL } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async searchUserByFullname(context, data) {
        try {
            const response = await api.apiPostAuth(API_INTERNAL.SEARCH_USER, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserFullname(context, data) {
        try {
            const response = await api.apiPostAuth(API_INTERNAL.GET_USER_FULLNAME, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getUserPartnership(context, data) {
        try {
            const response = await api.apiPostAuth(API_INTERNAL.GET_USER_PARTNERSHIP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraOpt(context, payload) {
        try {
            const response = await api.apiPostAuth(API_INTERNAL.GET_MITRA_OPT(payload), {user_ids: payload.user_ids});
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};


export default {
    namespaced,
    actions
};
