import api from '@/utils/api';
import { 
    API_REPORT,
    API_MARKETING
} from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getUserReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.USER, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.MITRA, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOfficeReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.OFFICE_LIST, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getEntryReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.ENTRY, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getFeedReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.FEED, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.JOB, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobDetail(context, job_id) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.DETAIL_JOB(job_id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCandidateReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.CANDIDATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async shareJobMarketing(context, job_id) {
        try {
            const response = await api.apiPutAuth(API_MARKETING.SHARE_JOB(job_id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyAttendanceTrackerReport(context, data) {
        try {
            const response = await api.apiGetAuth(API_REPORT.COMPANY_ATTENDANCE_TRACKER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
