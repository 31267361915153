import api from '@/utils/api';
import { 
    API_MARKETING
} from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getTestimonial(context, data) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.LIST_TESTIMONIAL, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showTestimonial(context, id) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.SPECIFIC_TESTIMONIAL(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteTestimonial(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MARKETING.SPECIFIC_TESTIMONIAL(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createTestimonial(context, data) {
        try {
            const response = await api.apiPostAuth(API_MARKETING.LIST_TESTIMONIAL, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateTestimonial(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MARKETING.SPECIFIC_TESTIMONIAL(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getBanner(context, data) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.LIST_BANNER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showBanner(context, id) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.SPECIFIC_BANNER(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteBanner(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MARKETING.SPECIFIC_BANNER(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createBanner(context, data) {
        try {
            const response = await api.apiPostAuth(API_MARKETING.LIST_BANNER, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateBanner(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MARKETING.SPECIFIC_BANNER(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCommunity(context, data) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.LIST_COMMUNITY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showCommunity(context, id) {
        try {
            const response = await api.apiGetAuth(API_MARKETING.SPECIFIC_COMMUNITY(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteCommunity(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MARKETING.SPECIFIC_COMMUNITY(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCommunity(context, data) {
        try {
            const response = await api.apiPostAuth(API_MARKETING.LIST_COMMUNITY, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateCommunity(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MARKETING.SPECIFIC_COMMUNITY(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

export default {
    namespaced,
    actions,
};
