import axios from 'axios';
import { METHOD } from './constant';
import { getUserToken } from './auth';

const api = {};
api.apiGetNonAuth = (URL, params) => axios({
    url: URL,
    method: METHOD.GET,
    params,
});

api.apiPostNonAuth = (URL, data) => axios({
    url: URL,
    method: METHOD.POST,
    data,
});

api.apiGetAuth = (URL, params) => axios({
    url: URL,
    method: METHOD.GET,
    params,
    headers: {
        Authorization: getUserToken()
    },
});

api.apiPostAuth = (URL, data) => axios({
    url: URL,
    method: METHOD.POST,
    data,
    headers: {
        Authorization: getUserToken()
    },
});

api.apiPatchAuth = (URL, data) => axios({
    url: URL,
    method: METHOD.PATCH,
    data,
    headers: {
        Authorization: getUserToken()
    },
});

api.apiPutAuth = (URL, data) => axios({
    url: URL,
    method: METHOD.PUT,
    data,
    headers: {
        Authorization: getUserToken()
    },
});

api.apiDeleteAuth = (URL, data) => axios({
    url: URL,
    method: METHOD.DELETE,
    data,
    headers: {
        Authorization: getUserToken()
    },
});

export default api;
