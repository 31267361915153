import Vue from 'vue';
import api from '@/utils/api';
import { API_JOB } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    jobs: {docs:[]},
    job: {},
    jobs_combine: {docs:[]},
    job_lists: {docs:[]}
};

const mutations = {
    "SET_JOBS": (state, payload) => {
        Vue.set(state, 'jobs', payload);
    },
    "SET_JOB_LISTS": (state, payload) => {
        Vue.set(state, 'job_lists', payload);
    },
    "SET_JOB": (state, payload) => {
        Vue.set(state, 'job', payload);
    },
    "SET_JOBS_COMBINE": (state, payload) => {
        Vue.set(state, 'jobs_combine', payload);
    }
};

const actions = {
    async getJobLists(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.LISTS, data);
            context.commit('SET_JOB_LISTS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJob(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.LIST_CREATE, data);
            context.commit('SET_JOBS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobCombine(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.LIST_COMBINE, data);
            context.commit('SET_JOBS_COMBINE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobDetail(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.DETAIL_UPDATE(data));
            context.commit('SET_JOB', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createJob(context, data) {
        try {
            const response = await api.apiPostAuth(API_JOB.LIST_CREATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateJob(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_JOB.DETAIL_UPDATE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobType(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.JOB_TYPE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },

    /* ================================================ Silver Package ================================================ */
    async createSilverPackage(context, data) {
        try {
            const response = await api.apiPostAuth(API_JOB.CREATE_SILVER_PACKAGE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSilverPackage(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.SILVER_PACKAGE_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async closeSilverPackage(context, data) {
        try {
            const response = await api.apiPutAuth(API_JOB.CLOSE_SLIVER_PACKAGE(data), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async reuploadSilverPackage(context, data) {
        try {
            const response = await api.apiPutAuth(API_JOB.REUPLOAD_SILVER_PACKAGE(data.id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showSilverPackage(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.DETAIL_SILVER_PACKAGE(data), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editSilverPackage(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(API_JOB.EDIT_SILVER_PACKAGE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async jobApplicantListSilverPackage(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.JOB_APPLICANT_SILVER_PACKAGE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadCvSilverPackage(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.DOWNLOAD_CV_APPLICANT_SILVER_PACKAGE(data), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async detailApplicantSilverPackage(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.DETAIL_JOB_APPLICANT_SILVER_PACKAGE(data), data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async listAllJobApplication(context, data) {
        try {
            const response = await api.apiGetAuth(API_JOB.LIST_ALL_JOB_APPLICATION, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {
    jobs: state => {
        return state.jobs;
    },
    job: state => {
        return state.job;
    },
    jobs_combine: state => {
        return state.jobs_combine;
    },
    job_lists: state => {
        return state.job_lists;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
