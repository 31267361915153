import api from '@/utils/api';
import {JOB_PREFERENCE} from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getJobPreferenceLists(context, data) {
        try {
            const response = await api.apiGetAuth(JOB_PREFERENCE.LIST_CREATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async showJobPreference(context, id) {
        try {
            const response = await api.apiGetAuth(JOB_PREFERENCE.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteJobPreference(context, id) {
        try {
            const response = await api.apiDeleteAuth(JOB_PREFERENCE.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createJobPreference(context, data) {
        try {
            const response = await api.apiPostAuth(JOB_PREFERENCE.LIST_CREATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editJobPreference(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(JOB_PREFERENCE.EDIT_JOB_PREFERENCE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async reorderJobPreferenceList(context, data) {
        try {
            const response = await api.apiPatchAuth(JOB_PREFERENCE.REORDER_LIST, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllJobPreferenceList(context, data) {
        try {
            const response = await api.apiGetAuth(JOB_PREFERENCE.LIST_ALL, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createBulkUpload(context, data) {
        try {
            const response = await api.apiPostAuth(JOB_PREFERENCE.BULK_UPLOAD, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};


export default {
    namespaced,
    actions
};
