import Vue from 'vue';
import Vuex from 'vuex';
import VeeValidate from 'vee-validate';
import App from './App.vue';
import router from './router';
import { store } from './store';
import Multiselect from 'vue-multiselect';
import Datetime from 'vue-datetime';
import VueClipboard from 'vue-clipboard2';
import JsonExcel from 'vue-json-excel';
import interceptorsSetup from '@/configs/axios';
import VModal from 'vue-js-modal';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VeeValidate, {
    fieldsBagName: 'vvFields'
});
Vue.use(VueClipboard);
Vue.component('multiselect', Multiselect);
Vue.component('datetime', Datetime);
Vue.component('downloadExcel', JsonExcel);
Vue.use(VModal);

interceptorsSetup();

new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>',
});
