<template>
    <div class="uk-width-1-4@s uk-margin-large-top">
        <div class=" uk-margin-medium-top uk-card uk-card-default uk-card-body uk-width-1-1@s">
            <h3 class="uk-card-title">{{ onSecureAuth ? 'Enter Key Auth' : 'Log in'}}</h3>
            <hr>
            <form v-if="!onSecureAuth" ref="myForm" @submit.prevent="onSubmit">
                <div class="uk-margin uk-text-left">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" uk-icon="icon: user"></span>
                        <input
                            name="email"
                            class="uk-input"
                            type="text"
                            placeholder="Email"
                            v-model="data.email"
                            v-validate="'required|email'"
                            :class="{'uk-form-danger': errors.has('email')}"
                        >
                    </div>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</span>
                </div>
                <div class="uk-margin uk-text-left">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input
                            name="password"
                            class="uk-input"
                            type="password"
                            placeholder="Password"
                            autocomplete="new-password"
                            v-model="data.password"
                            v-validate="'required|min:8'"
                            :class="{'uk-form-danger': errors.has('password')}"
                        >
                    </div>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('password')">{{ errors.first('password') }}</span>
                </div>
                <button v-if="!is_loading" class="uk-button uk-button-primary uk-width-1-1" type="submit" :disabled="is_loading">Login</button>
                <button v-else class="uk-button uk-button-primary uk-width-1-1" type="button" disabled><div uk-spinner></div></button>
            </form>
            <form v-else @submit.prevent="onSubmitSecureAuth">
                <div class="uk-margin uk-text-left">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" uk-icon="icon: user"></span>
                        <input
                                name="key-auth"
                                class="uk-input"
                                type="text"
                                placeholder="Key auth"
                                v-model="data.key"
                                v-validate="'required'"
                                :class="{'uk-form-danger': errors.has('key-auth')}"
                        >
                    </div>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('key-auth')">{{ errors.first('key-auth') }}</span>
                </div>
                <button v-if="!is_loading2" class="uk-button uk-button-primary uk-width-1-1" type="submit" :disabled="is_loading2">Login</button>
                <button v-else class="uk-button uk-button-primary uk-width-1-1" type="button" disabled><div uk-spinner></div></button>
            </form>
        </div>
        <!-- <p class="uk-text-muted uk-text-small">Forgot your password? <router-link :to="'/forgot-password'">Click here!</router-link></p> -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            payload: null,
            onSecureAuth: false,
            is_loading: false,
            is_loading2: false,
            data: {
                email: '',
                password: '',
                key: ''
            }
        };
    },
    methods: {
        ...mapActions({
            loginUser: 'auth/login',
            loginCredentialUser: 'auth/setLoginCredential'
        }),
        onSubmit() {
            this.$validator.validateAll().then((success) => {
                if (success) {
                    if (this.$validator.errors.any()) return;
                    this.login();
                }
            });
        },
        onSubmitSecureAuth() {
            this.$validator.validateAll().then((success) => {
                if (success) {
                    if (this.$validator.errors.any()) return;
                    this.loginCredential();
                }
            });
        },
        async login() {
            this.is_loading = true;
            const { data } = await this.loginUser(this.data);
            if (data.status === 'OK'){
                this.onSecureAuth = true;
                this.payload = data;
            }
            this.is_loading = false;
        },
        async loginCredential() {
            this.is_loading2 = true;
            await this.loginCredentialUser({ auth: this.payload, key: this.data.key });
            this.is_loading2 = false;
        },
    },
};
</script>
