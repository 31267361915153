// import Vue from 'vue';
import api from '@/utils/api';
import { API_COMPANY_OFFICE } from '@/utils/v4/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    
};

const mutations = {
    
};

const actions = {
    async getCompanyOfficeOptions(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_OFFICE.OPTION_LIST, data);
            return response;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
