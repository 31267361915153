import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_USER_ATTENDANCE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    attendances: {docs:[]},
    attendance: {},
};

const mutations = {
    "SET_ATTENDANCES": (state, payload) => {
        Vue.set(state, 'attendances', payload);
    },
    "SET_ATTENDANCE": (state, payload) => {
        Vue.set(state, 'attendance', payload);
    },
};

const actions = {
    async getAttendances(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_USER_ATTENDANCE.CREATE_RETRIEVE, data);
            context.commit('SET_ATTENDANCES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAttendanceDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_USER_ATTENDANCE.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_ATTENDANCE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createAttendance(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_USER_ATTENDANCE.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateAttendance(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_USER_ATTENDANCE.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteAttendance(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_USER_ATTENDANCE.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateEmployerEditFlags(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_USER_ATTENDANCE.UPDATE_EMPL_EDIT_FLAGS(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    attendances: state => {
        return state.attendances;
    },
    attendance: state => {
        return state.attendance;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
