import Vue from 'vue';
import Router from 'vue-router';
import { getUserLogin } from '@/utils/auth';

import EmptyPageLayout from '@/components/layouts/EmptyPage';
import Login from '@/components/pages/Login';

Vue.use(Router);

const router = new Router({
    mode: 'history', // remove # in url
    routes: [
        {
            path: '/',
            component: EmptyPageLayout,
            redirect: '/login',
            children: [
                {
                    path: '/login',
                    name: 'Login',
                    component: Login,
                    meta: {
                        public: true,
                        onlyWhenLoggedOut: true
                    }
                }
            ]
        },
        {
            path: '/admin',
            component: () => import(/* webpackChunkName: "layout-admin" */ '@/components/layouts/Admin'),
            redirect: '/admin/dashboard',
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/pages/admin/Dashboard')
                },
                {
                    path: 'dashboard/mitra-candidate',
                    name: 'Dashboard Mitra & Candidate',
                    component: () => import(/* webpackChunkName: "dashboard-mitra-candidate" */ '@/components/pages/admin/DashboardMitraCandidate')
                },
                {
                    path: 'contract',
                    name: 'Contract',
                    component: () => import(/* webpackChunkName: "contract" */ '@/components/pages/admin/Contract')
                },
                {
                    path: "contract/create",
                    name: "Contract-create",
                    component: () => import(/* webpackChunkName: "contract-create" */ '@/components/pages/admin/contract/create')
                },
                {
                    path: "contract/edit/:id",
                    name: "Contract-edit",
                    component: () => import(/* webpackChunkName: "contract-edit" */ '@/components/pages/admin/contract/edit')
                },
                {
                    path: 'candidate',
                    name: 'Candidate',
                    component: () => import(/* webpackChunkName: "candidate" */ '@/components/pages/admin/user/candidate')
                },
                {
                    path: 'candidate/detail/:user_id',
                    name: 'CandidateDetail',
                    component: () => import(/* webpackChunkName: "candidate-detail" */ '@/components/pages/admin/user/detail')
                },
                {
                    path: 'membership',
                    name: 'PremiumMembership',
                    component: () => import('@/components/v4/pages/admin/user/membership')
                },
                {
                    path: 'membership/detail/:user_id',
                    name: 'MembershipDetail',
                    component: () => import('@/components/v4/pages/admin/user/membership/Detail')
                },
                {
                    path: 'mitra',
                    name: 'Mitra',
                    component: () => import(/* webpackChunkName: "mitra" */ '@/components/pages/admin/user/mitra')
                },
                {
                    path: 'user-employer',
                    name: 'UserEmployer',
                    component: () => import(/* webpackChunkName: "user-employer" */ '@/components/pages/admin/user/employer')
                },
                {
                    path: 'all-user',
                    name: 'AllUser',
                    component: () => import(/* webpackChunkName: "all-user" */ '@/components/pages/admin/AllUser')
                },
                {
                    path: 'administrator',
                    name: 'Administrator',
                    component: () => import(/* webpackChunkName: "administrator" */ '@/components/pages/admin/user/administrator')
                },
                {
                    path: 'worker',
                    name: 'Worker',
                    component: () => import(/* webpackChunkName: "worker" */ '@/components/pages/admin/Worker')
                },
                {
                    path: 'employer',
                    name: 'Employer',
                    component: () => import(/* webpackChunkName: "employer" */ '@/components/pages/admin/Employer')
                },
                {
                    path: 'job',
                    name: 'Job',
                    component: () => import(/* webpackChunkName: "job" */ '@/components/pages/admin/Job')
                },
                {
                    path: 'platform-version',
                    name: 'PlatformVersion',
                    component: () => import(/* webpackChunkName: "platform-version" */ '@/components/pages/admin/PlatformVersion')
                },
                {
                    path: 'additional-document',
                    name: 'AdditionalDocument',
                    component: () => import(/* webpackChunkName: "additional-question" */ '@/components/pages/admin/additional_document/index')
                },
                {
                    path: 'additional-questions',
                    name: 'AdditionalQuestions',
                    component: () => import(/* webpackChunkName: "additional-question" */ '@/components/v4/pages/admin/extra/AdditionalQuestions')
                },
                {
                    path: 'additional-questions/:id',
                    name: 'DetailAdditionalQuestion',
                    component: () => import(/* webpackChunkName: "additional-question" */ '@/components/v4/pages/admin/extra/AdditionalQuestions/Detail')
                },
                {
                    path: 'job-preference',
                    name: 'JobPreference',
                    component: () => import(/* webpackChunkName: "job-preference" */ '@/components/pages/admin/JobPreference')
                },
                {
                    path: 'job-combine',
                    name: 'JobCombine',
                    component: () => import(/* webpackChunkName: "job-combine" */ '@/components/pages/admin/job_combine/JobCombine')
                },
                {
                    path: 'test-category',
                    name: 'TestCategory',
                    component: () => import(/* webpackChunkName: "test-category" */ '@/components/pages/admin/TestCategory')
                },
                {
                    path: 'test-question',
                    name: 'TestQuestion',
                    component: () => import(/* webpackChunkName: "test-question" */ '@/components/pages/admin/TestQuestion')
                },
                {
                    path: 'job-combine/createInstajob',
                    name: 'createInstajob',
                    component: () => import(/* webpackChunkName: "create-insta-job" */ '@/components/pages/admin/job_combine/createInstajob')
                },
                {
                    path: 'job-admin',
                    name: 'JobAdmin',
                    component: () => import(/* webpackChunkName: "job-admin" */ '@/components/pages/admin/job_admin')
                },
                {
                    path: 'job-admin/create',
                    name: 'JobAdminCreate',
                    component: () => import(/* webpackChunkName: "job-admin-create" */ '@/components/pages/admin/job_admin/Create')
                },
                {
                    path: 'job-admin/update/:job_id',
                    name: 'JobAdminUpdate',
                    component: () => import(/* webpackChunkName: "job-admin-update" */ '@/components/pages/admin/job_admin/Update')
                },
                {
                    path: 'cv-classification',
                    name: 'CVClassified',
                    component: () => import(/* webpackChunkName: "cv-classified" */ '@/components/pages/admin/cv_classified')
                },
                {
                    path: 'cv-classification/update/:cv_id',
                    name: 'CVClassifiedUpdate',
                    component: () => import(/* webpackChunkName: "cv-classified-update" */ '@/components/pages/admin/cv_classified/Update')
                },
                {
                    path: 'cv-classification/create',
                    name: 'CVClassifiedCreate',
                    component: () => import(/* webpackChunkName: "cv-classified-create" */ '@/components/pages/admin/cv_classified/Create')
                },
                {
                    path: 'post',
                    name: 'Post',
                    component: () => import(/* webpackChunkName: "post" */ '@/components/pages/admin/post/index')
                },
                {
                    path: 'applicant/whatsapp-template',
                    name: 'ApplicantWhatsappTemplate',
                    component: () => import(/* webpackChunkName: "applicant-whatsapp-template" */ '@/components/pages/admin/applicant/whatsapp_template')
                },
                {
                    path: 'applicant/:job_id',
                    name: 'Applicant',
                    component: () => import(/* webpackChunkName: "applicant" */ '@/components/pages/admin/Applicant')
                },
                {
                    path: 'post/create',
                    name: 'CreatePost',
                    component: () => import(/* webpackChunkName: "create-post" */ '@/components/pages/admin/post/create')
                },
                {
                    path: 'hospitality/category',
                    name: 'HospitalityCategory',
                    component: () => import(/* webpackChunkName: "hospitality-category" */ '@/components/pages/admin/hospitality/category/index')
                },
                {
                    path: 'hospitality/category/create',
                    name: 'CreateCategory',
                    component: () => import(/* webpackChunkName: "create-category" */ '@/components/pages/admin/hospitality/category/create')
                },
                {
                    path: 'hospitality/job/category/:category_seo_url',
                    name: 'HospitalityJob',
                    component: () => import(/* webpackChunkName: "hospitality-job" */ '@/components/pages/admin/hospitality/job/category')
                },
                {
                    path: 'hospitality/job/create',
                    name: 'CreateHospitalityJob',
                    component: () => import(/* webpackChunkName: "create-hospitality-job" */ '@/components/pages/admin/hospitality/job/create')
                },
                {
                    path: 'mitra/company',
                    name: 'Company',
                    component: () => import(/* webpackChunkName: "company" */ '@/components/pages/admin/company')
                },
                {
                    path: 'mitra/company-role',
                    name: 'CompanyRole',
                    component: () => import(/* webpackChunkName: "company-role" */ '@/components/pages/admin/CompanyRole')
                },
                {
                    path: 'mitra/company/detail/:company_id',
                    name: 'CompanyDetail',
                    component: () => import(/* webpackChunkName: "company-detail" */ '@/components/pages/admin/company/detail')
                },
                {
                    path: 'mitra/company/attendance/:company_id/:company_office_id',
                    name: 'CompanyAttendance',
                    component: () => import(/* webpackChunkName: "company-attendance" */ '@/components/pages/admin/company/attendance')
                },
                {
                    path: 'mitra/company/role_salary/:company_id/:company_office_id',
                    name: 'CompanyRoleSalary',
                    component: () => import(/* webpackChunkName: "company-role-salary" */ '@/components/pages/admin/company/role_salary')
                },
                {
                    path: 'mitra/company/assign_mitra/:company_id/:company_office_id',
                    name: 'CompanyAssignMitra',
                    component: () => import(/* webpackChunkName: "company-assign-mitra" */ '@/components/pages/admin/company/assign_mitra')
                },
                {
                    path: 'mitra/company/office_type/:company_id',
                    name: 'CompanyOfficeType',
                    component: () => import(/* webpackChunkName: "company-office-type" */ '@/components/pages/admin/company/office_type')
                },
                {
                    path: 'mitra/company/workers_request/:company_id',
                    name: 'CompanyWorkersRequest',
                    component: () => import(/* webpackChunkName: "company-workers-request" */ '@/components/pages/admin/company/workers_request')
                },
                {
                    path: 'mitra/company/setting/overtime-report-view/:company_id',
                    name: 'SettingOvertimeReportView',
                    component: () => import(/* webpackChunkName: "company-office-type" */ '@/components/pages/admin/company/setting/overtime_report_view')
                },
                {
                    path: 'mitra/property',
                    name: 'Property',
                    component: () => import(/* webpackChunkName: "property" */ '@/components/pages/admin/property')
                },
                {
                    path: 'mitra/property-type',
                    name: 'PropertyType',
                    component: () => import(/* webpackChunkName: "property-type" */ '@/components/pages/admin/PropertyType')
                },
                {
                    path: 'mitra/company-shift',
                    name: 'CompanyShift',
                    component: () => import(/* webpackChunkName: "company-shift" */ '@/components/pages/admin/CompanyShift')
                },
                {
                    path: 'mitra/workforce-assignment',
                    name: 'WorkersAssignment',
                    component: () => import(/* webpackChunkName: "workers-assignment" */ '@/components/pages/admin/workers_assignment')
                },
                {
                    path: 'mitra/workforce-assignment/detail/:company_office_id',
                    name: 'WorkersAssignmentDetail',
                    component: () => import(/* webpackChunkName: "workers-assignment-detail" */ '@/components/pages/admin/workers_assignment/detail')
                },
                {
                    path: 'mitra/user-attendance',
                    name: 'UserAttendance',
                    component: () => import(/* webpackChunkName: "user-attendance" */ '@/components/pages/admin/UserAttendance')
                },
                {
                    path: 'mitra/job-partner',
                    name: 'JobPartner',
                    component: () => import(/* webpackChunkName: "job-partner" */ '@/components/pages/admin/JobPartner')
                },
                {
                    path: 'mitra/daily-recap',
                    name: 'DailyRecap',
                    component: () => import(/* webpackChunkName: "daily-recap" */ '@/components/pages/admin/DailyRecap')
                },
                {
                    path: 'mitra/schedule',
                    name: 'Schedule',
                    component: () => import(/* webpackChunkName: "schedule" */ '@/components/pages/admin/schedule')
                },
                {
                    path: 'mitra/schedule/detail/:company_id/:company_office_id',
                    name: 'ScheduleDetail',
                    component: () => import(/* webpackChunkName: "schedule-detail" */ '@/components/pages/admin/schedule/detail')
                },
                {
                    path: 'mitra/property-role-setting',
                    name: 'PropertyRoleSetting',
                    component: () => import(/* webpackChunkName: "property-role-setting" */ '@/components/pages/admin/PropertyRoleSetting')
                },
                {
                    path: 'report/user',
                    name: 'UserReport',
                    component: () => import(/* webpackChunkName: "user-report" */ '@/components/pages/admin/report/user')
                },
                {
                    path: 'report/mitra',
                    name: 'MitraReport',
                    component: () => import(/* webpackChunkName: "mitra-report" */ '@/components/pages/admin/report/mitra')
                },
                {
                    path: 'report/company_attendance_tracker',
                    name: 'CompanyAttendanceTrackerReport',
                    component: () => import(/* webpackChunkName: "company-attendance-tracker-report" */ '@/components/pages/admin/report/company_attendance_tracker')
                },
                {
                    path: 'report/company',
                    name: 'CompanyReport',
                    component: () => import(/* webpackChunkName: "company-report" */ '@/components/pages/admin/report/company')
                },
                {
                    path: 'report/entry',
                    name: 'EntryUserReport',
                    component: () => import(/* webpackChunkName: "entry-user-report" */ '@/components/pages/admin/report/entry')
                },
                {
                    path: 'marketing/candidates',
                    name: 'MarketingCandidates',
                    component: () => import(/* webpackChunkName: "marketing-candidates" */ '@/components/pages/admin/marketing/candidates')
                },
                {
                    path: 'marketing/jobs',
                    name: 'MarketingJobs',
                    component: () => import(/* webpackChunkName: "marketing-jobs" */ '@/components/pages/admin/marketing/jobs')
                },
                {
                    path: 'marketing/jobs/detail/:job_id',
                    name: 'MarketingJobsDetail',
                    component: () => import(/* webpackChunkName: "marketing-jobs-detail" */ '@/components/pages/admin/marketing/jobs_detail')
                },
                {
                    path: 'marketing/posts',
                    name: 'MarketingPosts',
                    component: () => import(/* webpackChunkName: "marketing-posts" */ '@/components/pages/admin/marketing/posts')
                },
                {
                    path: 'marketing/testimonial',
                    name: 'MarketingTestimonial',
                    component: () => import(/* webpackChunkName: "marketing-testimonial" */ '@/components/pages/admin/marketing/testimonial')
                },
                {
                    path: 'marketing/banner',
                    name: 'MarketingBanner',
                    component: () => import(/* webpackChunkName: "marketing-banner" */ '@/components/pages/admin/marketing/banner')
                },
                {
                    path: 'marketing/silver_package',
                    name: 'MarketingSilverPackage',
                    component: () => import(/* webpackChunkName: "marketing-silver-package" */ '@/components/pages/admin/marketing/silver_package')
                },
                {
                    path: 'marketing/silver_package/create',
                    name: 'CreateSilverPackage',
                    component: () => import(/* webpackChunkName: "create-silver-package" */ '@/components/pages/admin/marketing/silver_package/create')
                },
                {
                    path: 'marketing/silver_package/detail/:id',
                    name: 'DetailSilverPackage',
                    component: () => import(/* webpackChunkName: "detail-silver-package" */ '@/components/pages/admin/marketing/silver_package/detail')
                },
                {
                    path: 'marketing/silver_package/edit/:id',
                    name: 'EditSilverPackage',
                    component: () => import(/* webpackChunkName: "edit-silver-package" */ '@/components/pages/admin/marketing/silver_package/edit')
                },
                {
                    path: 'marketing/silver_package/detail/:id/detail_job_applicant/:job_applicant_id',
                    name: 'DetailJobApplicantSilverPackage',
                    component: () => import(/* webpackChunkName: "detail-job-applicant-silver-package" */ '@/components/pages/admin/marketing/silver_package/detail_job_applicant')
                },
                {
                    path: 'community/live-content',
                    name: 'LiveContent',
                    component: () => import(/* webpackChunkName: "community" */ '@/components/v4/pages/admin/community/LiveContent')
                },
                {
                    path: 'community/live-content/:id',
                    name: 'LiveContentDetails',
                    component: () => import(/* webpackChunkName: "community" */ '@/components/v4/pages/admin/community/LiveContent/Details')
                },
                {
                    path: 'community/coupon',
                    name: 'Coupon',
                    component: () => import(/* webpackChunkName: "community" */ '@/components/pages/admin/community/coupon')
                },
                {
                    path: 'community/mywarung',
                    name: 'MyWarung',
                    component: () => import(/* webpackChunkName: "mywarung" */ '@/components/pages/admin/community/mywarung')
                },
                {
                    path: 'community/mywarung/limit',
                    name: 'MyWarungLimit',
                    component: () => import(/* webpackChunkName: "mywarunglimit" */ '@/components/pages/admin/community/mywarung_limit')
                },
                {
                    path: 'community/coupon/:coupon_id',
                    name: 'CouponDetail',
                    component: () => import(/* webpackChunkName: "community" */ '@/components/pages/admin/community/coupon_detail')
                },
                {
                    path: 'community/ewa',
                    name: 'EWA',
                    component: () => import(/* webpackChunkName: "ewa" */ '@/components/pages/admin/community/ewa/index')
                },
                {
                    path: 'community/ewa/vendor',
                    name: 'EWAVendor',
                    component: () => import(/* webpackChunkName: "ewavendor" */ '@/components/pages/admin/community/ewa/vendor/index')
                },
                {
                    path: 'community/mywarung/:warung_id',
                    name: 'MyWarungDetail',
                    component: () => import(/* webpackChunkName: "mywarung-detail" */ '@/components/pages/admin/community/mywarung_detail')
                },
                {
                    path: 'community/mywarung/:warung_id/assign',
                    name: 'MyWarungAssignMitra',
                    component: () => import(/* webpackChunkName: "mywarung-assign-mitra" */ '@/components/pages/admin/community/mywarung_assign')
                },
                {
                    path: 'community/mywarung/:warung_id/transaction/:user_id',
                    name: 'MyWarungMitraTransaction',
                    component: () => import(/* webpackChunkName: "mywarung-transaction-user" */ '@/components/pages/admin/community/mywarung_user_transation')
                },
                {
                    path: 'community/community',
                    name: 'Community',
                    component: () => import(/* webpackChunkName: "community" */ '@/components/pages/admin/community/community')
                },
                {
                    path: 'community/community/member/:group_id',
                    name: 'CommunityMember',
                    component: () => import(/* webpackChunkName: "community-topic" */ '@/components/pages/admin/community/community_member')
                },
                {
                    path: 'community/community/topic/:group_id',
                    name: 'CommunityTopic',
                    component: () => import(/* webpackChunkName: "community-topic" */ '@/components/pages/admin/community/community_topic')
                },
                {
                    path: 'marketing/social_media',
                    name: 'MarketingSocialMedia',
                    component: () => import(/* webpackChunkName: "marketing-social-media" */ '@/components/pages/admin/marketing/social_media')
                },
                {
                    path: 'leave',
                    name: 'Leave',
                    component: () => import(/* webpackChunkName: "leave" */ '@/components/pages/admin/Leave')
                },
                {
                    path: 'kormo/seekers',
                    name: 'KormoSeekers',
                    component: () => import(/* webpackChunkName: "kormo-seekers" */ '@/components/pages/admin/kormo/seekers')
                },
                {
                    path: '/whats-new',
                    name: 'WhatsNew',
                    component: () => import('@/components/pages/WhatsNew')
                },
                {
                    path: 'vacancy-reference',
                    name: 'VacancyReference',
                    component: () => import(/* webpackChunkName: "vacancy-reference" */ '@/components/v4/pages/admin/extra/VacancyReference')
                },
                {
                    path: 'admin-job-vacancy',
                    name: 'AdministratorJobVacancy',
                    component: () => import(/* webpackChunkName: "admin-job-vacancy" */ '@/components/v4/pages/admin/user/admin-job-vacancy')
                }
            ],
        },
        {
            path: '*',
            component: () => import(/* webpackChunkName: "notfound" */ '@/components/pages/NotFound'),
            meta: {
                public: true
            }
        },
    ],
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
    const loggedIn = getUserLogin();

    if (!isPublic && !loggedIn) {
        return next({
            path: '/login',
            query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
        });
    }

    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
        return next('/admin');
    }

    next();
});

export default router;
