import Vue from 'vue';
import api from '@/utils/api';
import { OPTION_DATA } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    educations: [],
    roles: [],
    shifts: [],
    internal_roles: []
};

const mutations = {
    "SET_EDUCATION": (state, payload) => {
        Vue.set(state, 'educations', payload);
    },
    "SET_ROLE": (state, payload) => {
        Vue.set(state, 'roles', payload);
    },
    "SET_SHIFT": (state, payload) => {
        Vue.set(state, 'shifts', payload);
    },
    "SET_INTERNAL_ROLE": (state, payload) => {
        Vue.set(state, 'internal_roles', payload);
    }
};

const actions = {
    async getEducation(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.EDUCATION, data);
            context.commit('SET_EDUCATION', response.data.result);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getJobPreference(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.JOB_PREFERENCE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCity(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.CITY, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGroup(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.GROUP, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRoles(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.ROLE, data);
            context.commit('SET_ROLE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRolesByOffice(context, data) {
        try {
            const response = await api.apiPostAuth(OPTION_DATA.ROLE_BY_OFFICE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShifts(context, data) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.SHIFT, data);
            context.commit('SET_SHIFT', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getShiftDetail(context, id) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.SHIFT_ITEM(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async postShift(context, data) {
        try {
            const response = await api.apiPostAuth(OPTION_DATA.SHIFT, data);
            return response.data.result;
        } catch (err) {
            console.warn(err);
        }
    },
    async postInviteUser(context, data) {
        try {
            const response = await api.apiPostAuth(OPTION_DATA.INVITE, data);
            return response.data;
        } catch (err) {
            console.warn(err);
        }
    },
    async checkShift(context, data) {
        try {
            const { id } = data;
            const response = await api.apiGetAuth(OPTION_DATA.CHECK_SHIFT(id));
            return response.data;
        } catch (err) {
            console.warn(err);
        }
    },
    async updateShift(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPutAuth(OPTION_DATA.SHIFT_ITEM(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteShift(context, data) {
        try {
            const { id } = data;
            const response = await api.apiDeleteAuth(OPTION_DATA.SHIFT_ITEM(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getInternalRoles(context) {
        try {
            const response = await api.apiGetAuth(OPTION_DATA.ROLE, {internal_shipper: true});
            context.commit('SET_INTERNAL_ROLE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    educations: state => {
        return state.educations;
    },
    roles: state => {
        return state.roles;
    },
    shifts: state => {
        return state.shifts;
    },
    internal_roles: state => {
        return state.internal_roles;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
