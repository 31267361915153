import api from '@/utils/api';
import { API_TEST_QUESTION } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getTestQuestionLists(context, data) {
        try {
            const response = await api.apiGetAuth(API_TEST_QUESTION.LIST_CREATE, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getSpecificTestQuestion(context, id) {
        try {
            const response = await api.apiGetAuth(API_TEST_QUESTION.SPECIFIC_UPDATE_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteTestQuestion(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_TEST_QUESTION.SPECIFIC_UPDATE_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createTestQuestion(context, data) {
        try {
            const response = await api.apiPostAuth(API_TEST_QUESTION.LIST_CREATE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateTestQuestion(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_TEST_QUESTION.SPECIFIC_UPDATE_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};


export default {
    namespaced,
    actions
};
