import Vue from 'vue';
import api from '@/utils/api';
import { API_CV_CLASSIFICATION } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    cv_classifications: {docs:[]},
    cv_classification: {},
    cv_datas: {
        type: [],
        status: [],
        count: 0
    }
};

const mutations = {
    "SET_CV_CLASSIFICATIONS": (state, payload) => {
        Vue.set(state, 'cv_classifications', payload);
    },
    "SET_CV_DATAS": (state, payload) => {
        Vue.set(state, 'cv_datas', payload);
    },
    "SET_CV_CLASSIFICATION": (state, payload) => {
        Vue.set(state, 'cv_classification', payload);
    }
};

const actions = {
    async getCVData(context, data) {
        try {
            const response = await api.apiGetAuth(API_CV_CLASSIFICATION.DATA, data);
            context.commit('SET_CV_DATAS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCV(context, data) {
        try {
            const response = await api.apiGetAuth(API_CV_CLASSIFICATION.LIST, data);
            context.commit('SET_CV_CLASSIFICATIONS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCVDetail(context, data) {
        try {
            const response = await api.apiGetAuth(API_CV_CLASSIFICATION.DETAIL(data));
            context.commit('SET_CV_CLASSIFICATION', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCV(context, data) {
        try {
            await api.apiPostAuth(API_CV_CLASSIFICATION.CREATE, data);
            return true;
        } catch (err) {
            notificationDanger(err);
            return false;
        }
    },
    async updateCV(context, {cvId, data}) {
        try {
            await api.apiPatchAuth(API_CV_CLASSIFICATION.UPDATE(cvId), data);
            return true;
        } catch (err) {
            notificationDanger(err);
            return false;
        }
    },
};

const getters = {
    cv_classifications: state => {
        return state.cv_classifications;
    },
    cv_datas: state => {
        return state.cv_datas;
    },
    cv_classification: state => {
        return state.cv_classification;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
