import Vue from 'vue';
import api from '@/utils/api';
import { OPEN_API } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    access_tokens: {docs:[]}
};

const mutations = {
    "SET_TOKENS": (state, payload) => {
        Vue.set(state, 'access_tokens', payload);
    }
};

const actions = {
    async getAccessTokens(context, data) {
        try {
            const response = await api.apiGetAuth(OPEN_API.GENERATE_RETRIEVE, data);
            context.commit('SET_TOKENS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async generateAccessToken(context, data) {
        try {
            const response = await api.apiPostAuth(OPEN_API.GENERATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async revokeAccessToken(context, id) {
        try {
            const response = await api.apiDeleteAuth(OPEN_API.DETAIL_REVOKE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    access_tokens: state => {
        return state.access_tokens;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
