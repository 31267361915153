import api from '@/utils/api';
import { API_WARUNG } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {
    async getWarung(context, payload) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.LIST_WARUNG, payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getMitraTransaction(context, payload) {
        try {
            const { id, meta } = payload;
            const response = await api.apiGetAuth(API_WARUNG.MITRA_TRANSACTION(id), meta);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAllAssignedMitra(context, payload) {
        try {
            const {id} = payload;
            const response = await api.apiGetAuth(API_WARUNG.ALL_ASSIGNED_MITRA(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getAssignedMitra(context, payload) {
        try {
            const {id, meta} = payload;
            const response = await api.apiPostAuth(API_WARUNG.LIST_ASSIGNED_MITRA(id), meta);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchWarung(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiPatchAuth(API_WARUNG.PATCH_WARUNG(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async patchMitraTransaction(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiPatchAuth(API_WARUNG.PATCH_MITRA_TRANSACTION(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createWarung(context, payload) {
        try {
            const response = await api.apiPostAuth(API_WARUNG.CREATE_WARUNG, payload);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async assignMitra(context, payload) {
        try {
            const { id, ...user_ids } = payload;
            const response = await api.apiPostAuth(API_WARUNG.ASSIGN_MITRA(id), user_ids);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteWarung(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_WARUNG.DELETE_WARUNG(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteAssignedMitra(context, payload) {
        try {
            const response = await api.apiDeleteAuth(API_WARUNG.DELETE_ASSIGNED_MITRA(payload));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOfficeOpt(context, company_id) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.GET_OFFICE_OPT(company_id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getRoleOpt(context, payload) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.GET_ROLE_OPT(payload));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGoods(context, id) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.GET_GOODS(id));
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getDetailWarung(context, id) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.GET_MODIFY_WARUNG(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadReportAssignedMitra(context, id) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.REPORT_WARUNG_ASSIGNED_MITRA(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadReportWarungTransaction(context, data) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.REPORT_WARUNG_TRANSACTION(data.id), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async addWarungProduct(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiPostAuth(API_WARUNG.GET_GOODS(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async editWarungProduct(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiPatchAuth(API_WARUNG.MODIFY_GOODS(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteWarungProduct(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_WARUNG.MODIFY_GOODS(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWarungTransaction(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiGetAuth(API_WARUNG.GET_WARUNG_TRANSACTION(id), formData);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteWarungTransaction(context, payload) {
        try {
            const response = await api.apiDeleteAuth(API_WARUNG.DELETE_TRANSACTION(payload));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async topupWarungBalance(context, payload) {
        try {
            const {id, formData} = payload;
            const response = await api.apiPostAuth(API_WARUNG.TOPUP_WARUNG(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getWarungLimit(context, payload) {
        try {
            const response = await api.apiGetAuth(API_WARUNG.GET_WARUNG_LIMIT, payload);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async SetLimitWarung(context, payload) {
        try {
            const {user_id, formData} = payload;
            const response = await api.apiPostAuth(API_WARUNG.SET_LIMIT_WARUNG(user_id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async resetWarungLImit(context, id) {
        try {
            const response = await api.apiPatchAuth(API_WARUNG.RESET_WARUNG_LIMIT(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async uploadWarungLImit(context, payload) {
        try {
            const response = await api.apiPostAuth(API_WARUNG.UPLOAD_WARUNG_LIMIT, payload, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
