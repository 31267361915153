const BASE_URL = `${process.env.VUE_APP_API_URL}/api/v4`;

// ========== USER ========== //
export const API_USER = {
    OPTION_LIST: `${BASE_URL}/user/option-list`
};
// ========================== //

// ========== COMPANY ========== //
export const API_COMPANY = {
    OPTION_LIST: `${BASE_URL}/company/option-list`
};
// ============================= //

// ========== COMPANY SETTING ========== //
export const API_COMPANY_SETTING = {
    RETRIEVE_OVERTIME_REPORT_VIEW: `${BASE_URL}/partner/setting/overtime_report_view`,
    UPDATE_OVERTIME_REPORT_VIEW: company_id => `${BASE_URL}/partner/setting/overtime_report_view/${company_id}`,
    OPTIONS_OVERTIME_REPORT_VIEW: `${BASE_URL}/partner/setting/overtime_report_view/office-role-option`
};
// ============================= //

// ========== COMPANY ROLE ========== //
export const API_COMPANY_ROLE = {
    OPTION_LIST: `${BASE_URL}/company-role/option-list`
};
// ================================== //

// ========== COMPANY OFFICE ========== //
export const API_COMPANY_OFFICE = {
    OPTION_LIST: `${BASE_URL}/company-office/option-list`
};
// ==================================== //

// ========== JOB ========== //
export const API_JOB = {
    OPTION_LIST: `${BASE_URL}/job/option-list`
};
// ========================= //

// ========== LIVE STREAM ========== //
export const API_LIVE_STREAM = {
    CREATE: `${BASE_URL}/live-stream/create`,
    SWITCH_STATUS: (id) => `${BASE_URL}/live-stream/switch-status/${id}`,
    EDIT: (id) => `${BASE_URL}/live-stream/edit/${id}`,
    DELETE: (id) => `${BASE_URL}/live-stream/delete/${id}`,
    LIST_PAGINATE: `${BASE_URL}/live-stream/list-paginate`,
    DETAIL: (id) => `${BASE_URL}/live-stream/detail/${id}`,
    DOWNLOAD_REPORT: (id) => `${BASE_URL}/live-stream/download-report/${id}`
};
// ================================== //

// ========== LIVE STREAM VISITOR LOG ========== //
export const API_LIVE_STREAM_VISITOR_LOG = {
    LIST_PAGINATE: `${BASE_URL}/live-stream-visitor-log/list-paginate`
};
// ============================================= //

// ========== LIVE STREAM VISITOR CHAT ========== //
export const API_LIVE_STREAM_CHAT = {
    LIST_PAGINATE: `${BASE_URL}/live-stream-chat/list-paginate`,
    USER_OPTIONS: `${BASE_URL}/live-stream-chat/user-options`
};
// ============================================== //

// ========== LIVE STREAM APPLICANT LOG ========== //
export const API_LIVE_STREAM_APPLICANT_LOG = {
    LIST_PAGINATE: `${BASE_URL}/live-stream-applicant-log/list-paginate`
};
// =============================================== //

// ========== MEMBERSHIP =========== //
export const API_MEMBERSHIP = {
    LIST_MEMBERSHIP: `${BASE_URL}/membership/list-membership`,
    DETAIL: (id) => `${BASE_URL}/membership/detail/${id}`,
    DETAIL_LIST: (userId) => `${BASE_URL}/membership/detail-list/${userId}`,
    DOWNLOAD_TEMPLATE: `${BASE_URL}/membership/download-template`,
    UPLOAD_TEMPLATE: `${BASE_URL}/membership/upload-template`
};
// ================================= //

// ========== VACANCY REFERENCE ===========
export const API_VACANCY_REFERENCE = {
    LIST_PAGINATE: `${BASE_URL}/vacancy-reference`,
    CREATE: `${BASE_URL}/vacancy-reference`,
    GET_BY_ID: (id) => `${BASE_URL}/vacancy-reference/${id}`,
    UPDATE: (id) => `${BASE_URL}/vacancy-reference/update/${id}`,
    DELETE: (id) => `${BASE_URL}/vacancy-reference/${id}`,
    REORDER: `${BASE_URL}/vacancy-reference/reorder-list`
};
// ================================= //

// =========== ADMIN JOB VACANCY =========== //
export const API_ADMIN_JOB_VACANCY = {
    LIST: `${BASE_URL}/admin-job-vacancy`,
    CREATE: `${BASE_URL}/admin-job-vacancy`,
    GET_BY_ID: (id) => `${BASE_URL}/admin-job-vacancy/${id}`,
    UPDATE: (id) => `${BASE_URL}/admin-job-vacancy/${id}`,
    DELETE: (id) => `${BASE_URL}/admin-job-vacancy/${id}`,
};
// ================================= //

// =========== ADDITIONAL QUESTION TEMPLATE CATEGORY =========== //
export const ADDITIONAL_QUESTION_TEMPLATE_CATEGORY = {
    CREATE: `${BASE_URL}/partner/additional-question-template-category/create-category`,
    LIST_PAGINATE: `${BASE_URL}/partner/additional-question-template-category/category-list-paginate`,
    EDIT: (id) => `${BASE_URL}/partner/additional-question-template-category/edit-category/${id}`,
    DELETE: (id) => `${BASE_URL}/partner/additional-question-template-category/delete-category/${id}`
};
// ============================================================= //

// =========== ADDITIONAL QUESTION TEMPLATE =========== //
export const ADDITIONAL_QUESTION_TEMPLATE = {
    CREATE: (categoryId) => `${BASE_URL}/partner/additional-question-template/${categoryId}/create-template`,
    LIST: (categoryId) => `${BASE_URL}/partner/additional-question-template/${categoryId}/template-list`,
    EDIT: (categoryId, id) => `${BASE_URL}/partner/additional-question-template/${categoryId}/edit-template/${id}`,
    DELETE: (categoryId, id) => `${BASE_URL}/partner/additional-question-template/${categoryId}/delete-template/${id}`,
};
// ==================================================== //

// =========== JOB APPLICANT =========== //
export const API_APPLICANT = {
    LIST_WHATSAPP_TEMPLATE: `${BASE_URL}/applicant/whatsapp-template`,
    CREATE_WHATSAPP_TEMPLATE: `${BASE_URL}/applicant/whatsapp-template`,
    UPDATE_WHATSAPP_TEMPLATE: id => `${BASE_URL}/applicant/whatsapp-template/${id}`,
    DELETE_WHATSAPP_TEMPLATE: id => `${BASE_URL}/applicant/whatsapp-template/${id}`,
};
// ================================= //
