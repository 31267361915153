import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_DAILY_SCHEDULE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    daily_schedules: {docs:[]},
};

const mutations = {
    "SET_DAILY_SCHEDULES": (state, payload) => {
        Vue.set(state, 'daily_schedules', payload);
    }
};

const actions = {
    async getDailySchedules(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_DAILY_SCHEDULE.CREATE_RETRIEVE, data);
            context.commit('SET_DAILY_SCHEDULES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createDailySchedule(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_DAILY_SCHEDULE.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateDailySchedule(context, {scheduleId, data}) {
        try {
            const response = await api.apiPatchAuth(API_MITRA_DAILY_SCHEDULE.UPDATE_DELETE(scheduleId), data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async autoCreateDailyRecap(context, data) {
        try {
            const response = await api.apiPutAuth(API_MITRA_DAILY_SCHEDULE.AUTO_CREATE_RECAP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteDailySchedule(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_DAILY_SCHEDULE.UPDATE_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    daily_schedules: state => {
        return state.daily_schedules;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
