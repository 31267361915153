// import Vue from 'vue';
import api from '@/utils/api';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { API_COMPANY_SETTING } from '@/utils/v4/api-url';

const namespaced = true;
const state = {
    
};

const mutations = {
    
};

const actions = {
    async getCompanySettingOvertimeReportView(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_SETTING.RETRIEVE_OVERTIME_REPORT_VIEW, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateCompanySettingOvertimeReportView(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_COMPANY_SETTING.UPDATE_OVERTIME_REPORT_VIEW(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getOfficeRoleOptionsCompanySettingOvertimeReportView(context, data) {
        try {
            const response = await api.apiGetAuth(API_COMPANY_SETTING.OPTIONS_OVERTIME_REPORT_VIEW, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
