import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_COMPANY } from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    companies: {docs:[]},
    company: {},
    company_industries: {docs:[]},
    company_logs: {docs:[]},
};

const mutations = {
    "SET_COMPANIES": (state, payload) => {
        Vue.set(state, 'companies', payload);
    },
    "SET_COMPANY": (state, payload) => {
        Vue.set(state, 'company', payload);
    },
    "SET_COMPANY_INDUSTRIES": (state, payload) => {
        Vue.set(state, 'company_industries', payload);
    },
    "SET_COMPANY_LOG": (state, payload) => {
        Vue.set(state, 'company_logs', payload);
    },
};

const actions = {
    async getCompanies(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.CREATE_RETRIEVE, data);
            context.commit('SET_COMPANIES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_COMPANY', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCompany(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_COMPANY.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateCompany(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_COMPANY.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateStatusCompany(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_COMPANY.UPDATE_STATUS(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteCompany(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_COMPANY.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyIndustries(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.RETRIEVE_INDUSTRIES, data);
            context.commit('SET_COMPANY_INDUSTRIES', response.data.result);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateWhitelist(context, data) {
        try {
            const {id, actionIsDelete, ...formData} = data;
            const response = actionIsDelete 
                ? await api.apiDeleteAuth(API_MITRA_COMPANY.UPDATE_WHITELIST(id), formData)
                : await api.apiPatchAuth(API_MITRA_COMPANY.UPDATE_WHITELIST(id), formData);
                
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyLog(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.RETRIEVE_LOGS, data);
            context.commit('SET_COMPANY_LOG', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyOpt() {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.GET_COMPANY_OPT);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async reorderCompanyList(context, data) {
        try {
            const response = await api.apiPatchAuth(API_MITRA_COMPANY.REORDER_COMPANY_LIST, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadOfficeTypeTemplate(context, companyId) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.OFFICE_TYPE_TEMPLATE(companyId));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async downloadOfficeTemplate(context, companyId) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.DOWNLOAD_OFFICE_TEMPLATE(companyId));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async uploadCreateOfficeTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_COMPANY.UPLOAD_CREATE_OFFICE_TEMPLATE(data.get('company_id')), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async getCompanyTemplateErrorLogList(context, companyId) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY.COMPANY_TEMPLATE_ERROR_LOG_LIST(companyId));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
};

const getters = {
    companies: state => {
        return state.companies;
    },
    company: state => {
        return state.company;
    },
    company_industries: state => {
        return state.company_industries;
    },
    company_logs: state => {
        return state.company_logs;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
