import api from '@/utils/api';
import { 
    API_PROFILE
} from '@/utils/api-url';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getCounter(context, data) {
        try {
            const response = await api.apiGetAuth(API_PROFILE.COUNTER, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getNotification(context, data) {
        try {
            const response = await api.apiGetAuth(API_PROFILE.NOTIFICATION, data);
            return response.data.result.docs;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async readNotification(context, data) {
        try {
            const response = await api.apiPatchAuth(API_PROFILE.READ_NOTIFICATION, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async readAllNotification(context, data) {
        try {
            const response = await api.apiPatchAuth(API_PROFILE.READ_ALL_NOTIFICATION, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

export default {
    namespaced,
    actions,
};
