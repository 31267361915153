import api from '@/utils/api';
import { API_KORMO } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;

const actions = {  
    async getSeekersPaginate(context, data) {
        try {
            const response = await api.apiGetAuth(API_KORMO.SEEKER_LIST, data);
            return response.data.result;
        } catch (err) {
            notificationDanger(err);
        }
    }
};

export default {
    namespaced,
    actions,
};
