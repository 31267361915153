import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import user from './modules/user';
import job from './modules/job';
import cv_classification from './modules/cv_classification';
import post from './modules/post';
import applicant from './modules/applicant';
import hospitality from './modules/hospitality';
import option_data from './modules/option_data';
import job_admin from './modules/job_admin';
import company from './modules/company';
import company_role from './modules/company_role';
import property_type from './modules/property_type';
import property from './modules/property';
import company_shift from './modules/company_shift';
import user_attendance from './modules/user_attendance';
import job_partner from './modules/job_partner';
import daily_recap from './modules/daily_recap';
import initiation from './modules/initiation';
import property_role_setting from './modules/property_role_setting';
import open_api from './modules/open_api';
import daily_schedule from './modules/daily_schedule';
import schedule from './modules/schedule';
import test_category from './modules/test_category';
import test_question from './modules/test_question';
import file from './modules/file';
import report from './modules/report';
import dashboard from './modules/dashboard';
import contract from './modules/contract';
import job_preference from './modules/job_preference';
import kormo from './modules/kormo';
import marketing from './modules/marketing';
import community from './modules/community';
import profile from './modules/profile';
import leave from './modules/leave';
import platform from './modules/platform';
import warung from './modules/warung';
import job_preference_category from './modules/job_preference_category';
import internal_api from './modules/internal_api';
import other_document from './modules/other_document';

// ========== V4 ========== //
import userV4 from './modules/v4/user';
import companyV4 from './modules/v4/company';
import companyRoleV4 from './modules/v4/company-role';
import companyOfficeV4 from './modules/v4/company-office';
import jobV4 from './modules/v4/job';
import liveStreamV4 from './modules/v4/live-stream';
import liveStreamVisitorLogV4 from './modules/v4/live-stream-visitor-log';
import liveStreamChatV4 from './modules/v4/live-stream-chat';
import liveStreamApplicantLogV4 from './modules/v4/live-stream-applicant-log';
import membershipV4 from './modules/v4/membership';
import vacancyRefV4 from './modules/v4/vacancy-reference';
import adminJobVacancyV4 from './modules/v4/admin-job-vacancy';
import additionalQuestionTemplateCategoryV4 from './modules/v4/additional-question-template-category';
import additionalQuestionTemplateV4 from './modules/v4/additional-question-template';
import applicantV4 from './modules/v4/applicant';
import companySettingV4 from './modules/v4/company-setting';
// ======================== //

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {},
    modules: {
        applicant,
        auth,
        company_role,
        company_shift,
        company,
        cv_classification,
        daily_recap,
        daily_schedule,
        hospitality,
        initiation,
        job_admin,
        job_partner,
        job,
        open_api,
        option_data,
        post,
        property_role_setting,
        property_type,
        property,
        user_attendance,
        user,
        schedule,
        test_category,
        test_question,
        file,
        report,
        dashboard,
        contract,
        job_preference,
        kormo,
        marketing,
        community,
        profile,
        leave,
        platform,
        warung,
        job_preference_category,
        internal_api,
        other_document,

        // ========== v4 ========== //
        userV4,
        companyV4,
        companyRoleV4,
        companyOfficeV4,
        jobV4,
        liveStreamV4,
        liveStreamVisitorLogV4,
        liveStreamChatV4,
        liveStreamApplicantLogV4,
        membershipV4,
        vacancyRefV4,
        adminJobVacancyV4,
        additionalQuestionTemplateCategoryV4,
        additionalQuestionTemplateV4,
        applicantV4,
        companySettingV4
        // ======================== //
    },
});
