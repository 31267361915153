import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_COMPANY_ROLE } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    company_roles: {docs:[]},
    company_role: {}
};

const mutations = {
    "SET_COMPANY_ROLES": (state, payload) => {
        Vue.set(state, 'company_roles', payload);
    },
    "SET_COMPANY_ROLE": (state, payload) => {
        Vue.set(state, 'company_role', payload);
    }
};

const actions = {
    async getCompanyRoles(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY_ROLE.CREATE_RETRIEVE, data);
            context.commit('SET_COMPANY_ROLES', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getCompanyRoleDetail(context, id) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY_ROLE.UPDATE_DETAIL_DELETE(id));
            context.commit('SET_COMPANY_ROLE', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createCompanyRole(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_COMPANY_ROLE.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateCompanyRole(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_COMPANY_ROLE.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteCompanyRole(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_COMPANY_ROLE.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async downloadCompanyRoleTemplate(context, companyId) {
        try {
            const response = await api.apiGetAuth(API_MITRA_COMPANY_ROLE.DOWNLOAD_COMPANY_ROLE_TEMPLATE(companyId));
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
    async uploadJobTitleTemplate(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_COMPANY_ROLE.UPLOAD_JOB_TITLE_TEMPLATE(data.get('company_id')), data);
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
};

const getters = {
    company_roles: state => {
        return state.company_roles;
    },
    company_role: state => {
        return state.company_role;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
