import Vue from 'vue';
import api from '@/utils/api';
import { API_MITRA_DAILY_RECAP } from '@/utils/api-url';
import {
    notificationDanger
} from '@/utils/notification';

const namespaced = true;
const state = {
    daily_recaps: {docs:[]},
    activity_logs: {docs:[]},
    grouped_recap: []
};

const mutations = {
    "SET_DAILY_RECAPS": (state, payload) => {
        Vue.set(state, 'daily_recaps', payload);
    },
    "SET_ACTIVITY_LOG": (state, payload) => {
        Vue.set(state, 'activity_logs', payload);
    },
    "SET_GROUPED": (state, payload) => {
        Vue.set(state, 'grouped_recap', payload);
    }
};

const actions = {
    async getDailyRecaps(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_DAILY_RECAP.CREATE_RETRIEVE, data);
            context.commit('SET_DAILY_RECAPS', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async createDailyRecap(context, data) {
        try {
            const response = await api.apiPostAuth(API_MITRA_DAILY_RECAP.CREATE_RETRIEVE, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async updateDailyRecap(context, data) {
        try {
            const {id, ...formData} = data;
            const response = await api.apiPatchAuth(API_MITRA_DAILY_RECAP.UPDATE_DETAIL_DELETE(id), formData);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async autoCreateDailyRecap(context, data) {
        try {
            const response = await api.apiPutAuth(API_MITRA_DAILY_RECAP.AUTO_CREATE_RECAP, data);
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async deleteDailyRecap(context, id) {
        try {
            const response = await api.apiDeleteAuth(API_MITRA_DAILY_RECAP.UPDATE_DETAIL_DELETE(id));
            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getActivityLog(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_DAILY_RECAP.ACTIVITY_LOG, data);
            context.commit('SET_ACTIVITY_LOG', response.data.result);
        } catch (err) {
            notificationDanger(err);
        }
    },
    async getGroupedRecap(context, data) {
        try {
            const response = await api.apiGetAuth(API_MITRA_DAILY_RECAP.GROUPED_RECAP, data);
            context.commit('SET_GROUPED', response.data.result);

            return response.data;
        } catch (err) {
            notificationDanger(err);
        }
    },
};

const getters = {
    daily_recaps: state => {
        return state.daily_recaps;
    },
    activity_logs: state => {
        return state.activity_logs;
    },
    grouped_recap: state => {
        return state.grouped_recap;
    }
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
