const BASE_URL = `${process.env.VUE_APP_API_URL}/api`;
const WARUNG_URL = `${process.env.VUE_APP_MYWARUNG}/api`;

// Auth
export const API_AUTH = {
    LOGIN: `${BASE_URL}/auth/login`,
    LOGOUT: `${BASE_URL}/auth/logout`,
    VALIDATE_SECURE_AUTH: `${BASE_URL}/auth/validate-secure-auth`,
};

export const API_PROFILE = {
    COUNTER: `${BASE_URL}/notification/counter`,
    NOTIFICATION: `${BASE_URL}/notification`,
    READ_NOTIFICATION: `${BASE_URL}/notification/read-notification`,
    READ_ALL_NOTIFICATION: `${BASE_URL}/notification/read-notification-all`,
};

// internal API to serve other microservice
export const API_INTERNAL = {
    SEARCH_USER: `${BASE_URL}/internal-api/search-user`,
    GET_USER_FULLNAME: `${BASE_URL}/internal-api/get-user-fullname`,
    GET_USER_PARTNERSHIP: `${BASE_URL}/internal-api/get-user-partnership`,
    GET_MITRA_OPT: payload => `${BASE_URL}/internal-api/warung-mitra-option?company_id=${payload.c_id}&company_office_id=${payload.o_id}&role_id=${payload.r_id}`,
};

export const API_WARUNG = {
    GET_OFFICE_OPT: id => `${BASE_URL}/mitra/company-office/option?company_id=${id}`,
    GET_ROLE_OPT: payload => `${BASE_URL}/mitra/company-role/option?company_id=${payload.c_id}&company_office_id=${payload.o_id}`,
    LIST_WARUNG: `${WARUNG_URL}/warung`,
    ALL_ASSIGNED_MITRA: id => `${WARUNG_URL}/warung/${id}/all-assigned-mitra`,
    LIST_ASSIGNED_MITRA: id => `${WARUNG_URL}/warung/${id}/assigned-mitra`,
    PATCH_WARUNG: id => `${WARUNG_URL}/warung/${id}`,
    PATCH_MITRA_TRANSACTION: payload => `${WARUNG_URL}/warung/${payload.w_id}/transaction/${payload.id}`,
    ASSIGN_MITRA: id => `${WARUNG_URL}/warung/${id}/assign-mitra`,
    CREATE_WARUNG: `${WARUNG_URL}/warung`,
    DELETE_WARUNG: id => `${WARUNG_URL}/warung/${id}`,
    GET_GOODS: id => `${WARUNG_URL}/warung/${id}/product`,
    DELETE_ASSIGNED_MITRA: payload => `${WARUNG_URL}/warung/${payload.w_id}/assigned-mitra/${payload.id}`,
    MITRA_TRANSACTION: payload => `${WARUNG_URL}/warung/${payload.w_id}/transaction/${payload.u_id}`,
    GET_MODIFY_WARUNG: id => `${WARUNG_URL}/warung/${id}`,
    MODIFY_GOODS: payload => `${WARUNG_URL}/warung/${payload.w_id}/product/${payload.p_id}`,
    GET_WARUNG_TRANSACTION: id => `${WARUNG_URL}/warung/${id}/transaction`,
    DELETE_TRANSACTION: payload => `${WARUNG_URL}/warung/${payload.w_id}/transaction/${payload.t_id}`,
    TOPUP_WARUNG: id => `${WARUNG_URL}/warung/${id}/topup-balance`,
    REPORT_WARUNG_TRANSACTION: id => `${WARUNG_URL}/warung/${id}/report/transaction`,
    REPORT_WARUNG_ASSIGNED_MITRA: id => `${WARUNG_URL}/warung/${id}/report/assigned-mitra`,
    GET_WARUNG_LIMIT: `${WARUNG_URL}/limit`,
    SET_LIMIT_WARUNG: id => `${WARUNG_URL}/limit/set-limit/${id}`,
    RESET_WARUNG_LIMIT: id => `${WARUNG_URL}/limit/reset/${id}`,
    UPLOAD_WARUNG_LIMIT: `${WARUNG_URL}/limit/upload`,
};

export const API_OTHER_DOCUMENT = {
    LIST_DOCUMENT: `${BASE_URL}/other-docs`,
    DELETE_DOCUMENT: id => `${BASE_URL}/other-docs/${id}`,
    CREATE_DOCUMENT: `${BASE_URL}/other-docs`,
    PATCH_DOCUMENT: id => `${BASE_URL}/other-docs/${id}`,
};

// User
export const API_USER = {
    CREATE_LIST: `${BASE_URL}/user`,
    LIST_CANDIDATE: `${BASE_URL}/user/candidate`,
    CREATE_LIST_PARTNER: `${BASE_URL}/user/partner`,
    DETAIL: id => `${BASE_URL}/user/${id}`,
    GET_CV: id => `${BASE_URL}/user/cv-pdf/${id}`,
    REPORT_USER: `${BASE_URL}/user/all-data`,
    UPDATE: id => `${BASE_URL}/user/${id}`,
    DELETE: id => `${BASE_URL}/user/${id}`,
    CANDIDATE: `${BASE_URL}/user/type/candidate`,
    EMPLOYER: `${BASE_URL}/user/type/employer`,
    CHANGE_CANDIDATE_STATUS: (user_id, is_active) => `${BASE_URL}/user/type/candidate/${user_id}/${is_active}`,
    BLOCK_SELECT_CANDIDATE: (user_ids, is_active) => `${BASE_URL}/user/type/candidate/bulk/${user_ids}/${is_active}`,
    SEND_SELECT_NOTIF_CANDIDATE: (user_ids, message) => `${BASE_URL}/user/type/candidate/bulk/notif/${user_ids}/${message}`,
    CHANGE_EMPLOYER_STATUS: (user_id, is_active) => `${BASE_URL}/user/type/employer/${user_id}/${is_active}`,
    BLOCK_SELECT_EMPLOYER: (user_ids, is_active) => `${BASE_URL}/user/type/employer/bulk/${user_ids}/${is_active}`,
    SEND_SELECT_NOTIF_EMPLOYER: (user_ids, message) => `${BASE_URL}/user/type/employer/bulk/notif/${user_ids}/${message}`,
    ADD_DELETE_PARTNER: id => `${BASE_URL}/user/partner/${id}`,
    LISTS: (roles, scope) => `${BASE_URL}/user/lists/${roles}/${scope}`,
    BULK_UPLOAD_CANDIDATE: `${BASE_URL}/user/candidate/bulk/upload`,

    CREATE_EDUCATION: `${BASE_URL}/education`,
    UPDATE_DELETE_EDUCATION: id => `${BASE_URL}/education/${id}`,
    CREATE_WORK_EXP: `${BASE_URL}/work-experience`,
    UPDATE_DELETE_WORK_EXP: id => `${BASE_URL}/work-experience/${id}`,
    CREATE_SKILL: `${BASE_URL}/skill`,
    UPLOAD_OTHER_DOCUMENT: (folder, user_id) => `${BASE_URL}/other-document/${folder}/user/${user_id}`,
    DELETE_OTHER_DOCUMENT: id => `${BASE_URL}/other-document/${id}`,
    UPLOAD_DOCUMENT: (folder, type, user_id) => `${BASE_URL}/other-document/${folder}/${type}/user/${user_id}`,
};

// Job
export const API_JOB = {
    LIST_CREATE: `${BASE_URL}/job`,
    LISTS: `${BASE_URL}/job/job-lists`,
    LIST_COMBINE: `${BASE_URL}/job/combine/all`,
    ACTION: (job_id, action) => `${BASE_URL}/job/${job_id}/${action}`,
    DETAIL_UPDATE: job_id => `${BASE_URL}/job/${job_id}`,
    JOB_TYPE: `${BASE_URL}/job/job-type`,

    /* =============================================== Silver Package =============================================== */
    CREATE_SILVER_PACKAGE: `${BASE_URL}/job/silver-package`,
    SILVER_PACKAGE_LIST: `${BASE_URL}/job/silver-package/list`,
    CLOSE_SLIVER_PACKAGE: (id) => `${BASE_URL}/job/silver-package/close/${id}`,
    REUPLOAD_SILVER_PACKAGE: (id) => `${BASE_URL}/job/silver-package/reupload/${id}`,
    DETAIL_SILVER_PACKAGE: (id) => `${BASE_URL}/job/silver-package/detail/${id}`,
    EDIT_SILVER_PACKAGE: (id) => `${BASE_URL}/job/silver-package/edit/${id}`,
    JOB_APPLICANT_SILVER_PACKAGE: `${BASE_URL}/job/silver-package/job-applicant-list`,
    DOWNLOAD_CV_APPLICANT_SILVER_PACKAGE: (user_id) => `${BASE_URL}/job/silver-package/cv?user_id=${user_id}`,
    DETAIL_JOB_APPLICANT_SILVER_PACKAGE: (id) => `${BASE_URL}/job/silver-package/detail-applicant/${id}`,
    LIST_ALL_JOB_APPLICATION: `${BASE_URL}/job/silver-package/all-application`,
};

// Test Category
export const API_TEST_CATEGORY = {
    LIST_CREATE: `${BASE_URL}/test-category`,
    LIST_NON_PAGINATE: `${BASE_URL}/test-category/list`,
    SPECIFIC_UPDATE_DELETE: id => `${BASE_URL}/test-category/${id}`
};

// Test Question
export const API_TEST_QUESTION= {
    LIST_CREATE: `${BASE_URL}/test-question`,
    SPECIFIC_UPDATE_DELETE: id => `${BASE_URL}/test-question/${id}`
};

// CV Classification
export const API_CV_CLASSIFICATION = {
    DATA: `${BASE_URL}/cv-classification/data-cv`,
    LIST: `${BASE_URL}/cv-classification`,
    CREATE: `${BASE_URL}/cv-classification`,
    DETAIL: cv_id => `${BASE_URL}/cv-classification/${cv_id}`,
    UPDATE: cv_id => `${BASE_URL}/cv-classification/${cv_id}`
};

// Job Admin
export const API_JOB_ADMIN = {
    DATA: `${BASE_URL}/job-admin/data-job`,
    LIST: `${BASE_URL}/job-admin`,
    CREATE: `${BASE_URL}/job-admin`,
    DETAIL: job_id => `${BASE_URL}/job-admin/${job_id}`,
    UPDATE: job_id => `${BASE_URL}/job-admin/${job_id}`
};

// User
export const API_APPLICANT = {
    LIST: job_id => `${BASE_URL}/applicant/${job_id}`,
    SET_SCORE: applicant_id => `${BASE_URL}/applicant/set_score/${applicant_id}`
};

// Category
export const API_HOSPITALITY = {
    CATEGORY_LIST: `${BASE_URL}/hospitality/resource/all-category`,
    CATEGORY_LIST_UNPAGINATE: `${BASE_URL}/hospitality/resource/category/unpaginate`,
    CHANGE_CATEGORY_STATUS: (category_id, is_active) => `${BASE_URL}/hospitality/resource/category/${category_id}/${is_active}`,
    UPDATE_CATEGORY: category_id => `${BASE_URL}/hospitality/resource/category/${category_id}`,
    CREATE_CATEGORY: `${BASE_URL}/hospitality/resource/category`,
    JOB_LIST: category_seo_url => `${BASE_URL}/hospitality/resource/job-description/${category_seo_url}`,
    CHANGE_JOB_STATUS: (job_id, is_active) => `${BASE_URL}/hospitality/resource/job-description/${job_id}/${is_active}`,
    UPDATE_JOB: job_id => `${BASE_URL}/hospitality/resource/job-description/${job_id}`,
    CREATE_JOB: `${BASE_URL}/hospitality/resource/job-description`
};

// Post
export const INSTAJOB_API_POST = {
    ALL: `${BASE_URL}/instajob/post/all`,
    DETAIL: id => `${BASE_URL}/instajob/post/detail/${id}`,
    CHANGE_STATUS: (id, status) => `${BASE_URL}/instajob/post/change_status/${id}/${status}`,
    UPDATE: id => `${BASE_URL}/instajob/post/${id}`,
    CREATE: `${BASE_URL}/instajob/post`
};

// New Initiations
export const API_INITIATION = {
    CREATE_RETRIEVE: `${BASE_URL}/initiation`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/initiation/${id}`,
};

// Coupon
export const API_COUPON = {
    CREATE_RETRIEVE: `${BASE_URL}/coupon`,
};

// Mitra Company
export const API_MITRA_COMPANY = {
    GET_COMPANY_OPT: `${BASE_URL}/company/option`,
    CREATE_RETRIEVE: `${BASE_URL}/company`,
    RETRIEVE_INDUSTRIES: `${BASE_URL}/company-industry`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/company/${id}`,
    UPDATE_WHITELIST: id => `${BASE_URL}/company/whitelist/${id}`,
    UPDATE_STATUS: id => `${BASE_URL}/company/${id}/status`,
    RETRIEVE_LOGS: `${BASE_URL}/company-log`,
    REORDER_COMPANY_LIST: `${BASE_URL}/company/reorder/company-list`,
    OFFICE_TYPE_TEMPLATE: company_id => `${BASE_URL}/mitra/office-type/download-office-type-template/${company_id}` ,
    DOWNLOAD_OFFICE_TEMPLATE: company_id => `${BASE_URL}/mitra/company-office/download-office-template/${company_id}`,
    UPLOAD_CREATE_OFFICE_TEMPLATE: (company_id) => `${BASE_URL}/mitra/company-office/upload-create-office-template/${company_id}`,
    COMPANY_TEMPLATE_ERROR_LOG_LIST: (company_id) => `${BASE_URL}/company/company-template-error-log-list/${company_id}`
};

export const API_MITRA_COMPANY_ROLE = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/company-role`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/company-role/${id}`,
    DOWNLOAD_COMPANY_ROLE_TEMPLATE: company_id => `${BASE_URL}/mitra/company-role/download-company-role-template/${company_id}`,
    UPLOAD_JOB_TITLE_TEMPLATE: (company_id) => `${BASE_URL}/mitra/company-role/upload-company-role-template/${company_id}`
};

// Property
export const API_MITRA_PROPERTY_TYPE = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/office-type`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/office-type/${id}`,
    UPLOAD_OFFICE_TYPE_TEMPLATE: (company_id) => `${BASE_URL}/mitra/office-type/upload-office-type-template/${company_id}`
};

export const API_MITRA_PROPERTY = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/company-office`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/company-office/${id}`,
    RETRIEVE_LOGS: `${BASE_URL}/mitra/office-log`,
};

export const API_MITRA_SHIFT = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/company-shift`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/company-shift/${id}`,
};

export const API_MITRA_USER_ATTENDANCE = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/user-attendance`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/user-attendance/${id}`,
    UPDATE_EMPL_EDIT_FLAGS: id => `${BASE_URL}/mitra/user-attendance/update-employer-edit/${id}`,
};

export const API_MITRA_JOB_PARTNER = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/job-partner`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/job-partner/${id}`,
};

export const API_MITRA_DAILY_RECAP = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/daily-recap`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/daily-recap/${id}`,
    AUTO_CREATE_RECAP: `${BASE_URL}/mitra/daily-recap/create-day-end-recap`,
    ACTIVITY_LOG: `${BASE_URL}/mitra/activity-log`,
    GROUPED_RECAP: `${BASE_URL}/mitra/daily-recap/grouped-data`,
};

export const API_MITRA_PROPERTY_ROLE = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/role-office-setting`,
    LIST_NON_PAGINATE: `${BASE_URL}/mitra/role-office-setting/list`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/role-office-setting/${id}`,
};

export const API_MITRA_SCHEDULE = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/schedule`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/mitra/schedule/${id}`,
    GET_USER_PARTNER: `${BASE_URL}/mitra/user-partner/all`,
    GET_ALL_COMPANY_SHIFT: `${BASE_URL}/mitra/company-shift/all`,
    CHECK_AVAILABLE: `${BASE_URL}/mitra/schedule/check-exists`,
    DELETE: `${BASE_URL}/mitra/schedule`,
};

export const OPEN_API = {
    GENERATE_RETRIEVE: `${BASE_URL}/open-api/partner`,
    DETAIL_REVOKE: id => `${BASE_URL}/open-api/partner/${id}`,
};

export const INSTAJOB_API_FILE = {
    IMAGE: folder_name => `${BASE_URL}/instajob/post/file/${folder_name}`
};

export const API_FILE = {
    FILE: (folder_name, category) => `${BASE_URL}/upload/${category}/${folder_name}`,
    DOCUMENT: (folder_name, category) => `${BASE_URL}/upload/document/${category}/${folder_name}`,
    IMAGE: (folder_name, category) => `${BASE_URL}/upload/image/${category}/${folder_name}`,
    IMAGEPDF: (folder_name, category) => `${BASE_URL}/upload/image-pdf/${category}/${folder_name}`
};

export const API_REPORT = {
    USER: `${BASE_URL}/report/user`,
    MITRA: `${BASE_URL}/report/mitra`,
    OFFICE_LIST: `${BASE_URL}/report/company/office`,
    ENTRY: `${BASE_URL}/report/entry`,
    COMPANY_ATTENDANCE_TRACKER: `${BASE_URL}/report/company_attendance_tracker`,
};

export const API_MARKETING = {
    CANDIDATE: `${BASE_URL}/report/marketing/user-candidate`,
    JOB: `${BASE_URL}/report/marketing/job`,
    DETAIL_JOB: job_id => `${BASE_URL}/report/marketing/job/${job_id}`,
    SHARE_JOB: job_id => `${BASE_URL}/report/marketing/shared/job/${job_id}`,
    FEED: `${BASE_URL}/report/marketing/feed`,
    LIST_TESTIMONIAL: `${BASE_URL}/testimonial/`,
    SPECIFIC_TESTIMONIAL: id => `${BASE_URL}/testimonial/${id}`,
    LIST_BANNER: `${BASE_URL}/banner/`,
    SPECIFIC_BANNER: id => `${BASE_URL}/banner/${id}`,
    LIST_COMMUNITY: `${BASE_URL}/community`,
    SPECIFIC_COMMUNITY: id => `${BASE_URL}/community/${id}`,
    COMPANY: `${BASE_URL}/report/company/office`,
};

export const API_COMMUNITY = {
    LIST_DETAIL_COUPON: id => `${BASE_URL}/coupon/${id}`,
    UPDATE_COUPON: id => `${BASE_URL}/coupon/update/${id}`,
    DELETE_COUPON: id =>`${BASE_URL}/coupon/${id}`,
    LIST_COUPON: `${BASE_URL}/coupon`,
    DOWNLOAD_TEMPLATE_VOUCHER: id => `${BASE_URL}/coupon/${id}/template/download`,
    UPLOAD_TEMPLATE_VOUCHER: id => `${BASE_URL}/coupon/${id}/upload`,
    LIST_MEMBER_GROUP: id => `${BASE_URL}/group/member/${id}`,
    LIST_GROUP: `${BASE_URL}/group`,
    BANNED_SPECIFIC_MEMBER: `${BASE_URL}/group/member/banned`,
    MOVE_SPECIFIC_MEMBER: `${BASE_URL}/group/member/move`,
    LIST_GROUP_NON_PAGINATE: `${BASE_URL}/group/list`,
    SPECIFIC_GROUP: id => `${BASE_URL}/group/${id}`,
    SPECIFIC_MEMBER_GROUP: payload => `${BASE_URL}/group/member/${payload.group_id}/${payload.member_id}`,
    LIST_TOPIC: `${BASE_URL}/group-topic`,
    SPECIFIC_TOPIC: id => `${BASE_URL}/group-topic/${id}`,
    LIST_EWA: `${BASE_URL}/ewa`,
    DETAIL_EWA: id => `${BASE_URL}/ewa/${id}`,
    ACCEPT_EWA: id => `${BASE_URL}/ewa/${id}/accept`,
    REJECT_EWA: id =>`${BASE_URL}/ewa/${id}/reject`,
    LIST_EWA_VENDOR: `${BASE_URL}/ewa/vendor`,
    SPECIFIC_EWA_VENDOR: id => `${BASE_URL}/ewa/vendor/${id}`
};

export const API_DASHBOARD = {
    RETRIEVE_TOTAL_USER: `${BASE_URL}/dashboard/total-user`,
    RETRIEVE_TOTAL_NEW_USER: `${BASE_URL}/dashboard/total-new-user`,
    RETRIEVE_MITRA_ON_CITY: `${BASE_URL}/dashboard/mitra-on-city`,
    RETRIEVE_CANDIDATE_ON_CITY: `${BASE_URL}/dashboard/candidate-on-city`,
    RETRIEVE_NEW_CANDIDATE: `${BASE_URL}/dashboard/new-candidate`,
    RETRIEVE_CANDIDATE_ON_NEW_PWA: `${BASE_URL}/dashboard/candidate-on-new-pwa`,
};

// KORMO
export const API_KORMO = {
    SEEKER_LIST: `${BASE_URL}/kormo/seekers`
};

// Option data
export const OPTION_DATA = {
    EDUCATION: `${BASE_URL}/education`,
    JOB_PREFERENCE: `${BASE_URL}/user/job-preference`,
    CITY: `${BASE_URL}/geograph/city`,
    GROUP: `${BASE_URL}/group/list-invite`,
    ROLE: `${BASE_URL}/mitra/company-role`,
    ROLE_BY_OFFICE: `${BASE_URL}/mitra/company-role/get-used-role-list`,
    SHIFT: `${BASE_URL}/mitra/company-shift`,
    INVITE: `${BASE_URL}/group/member/invite`,
    SHIFT_ITEM: id => `${BASE_URL}/mitra/company-shift/${id}`,
    CHECK_SHIFT: id => `${BASE_URL}/mitra/company-shift/check-exists/${id}`,
};

// Grabwheels workers assignment data
export const API_MITRA_DAILY_SCHEDULE = {
    CREATE_RETRIEVE: `${BASE_URL}/mitra/daily-schedule`,
    UPDATE_DELETE: id => `${BASE_URL}/mitra/daily-schedule/${id}`
};

// Contract
export const CONTRACT = {
    LIST_CREATE: `${BASE_URL}/contract`,
    UPDATE_DETAIL: id => `${BASE_URL}/contract/${id}`,
    FOUNDER_SIGNATURE: `${BASE_URL}/contract/founder-signature/upload`
};

// Job preference category
export const JOB_PREFERENCE_CATEGORY = {
    GET_LIST_PAGINATE: `${BASE_URL}/job-preference-category/list-paginate`,
    CREATE_CATEGORY: `${BASE_URL}/job-preference-category/create`,
    EDIT_CATEGORY: (id) => `${BASE_URL}/job-preference-category/edit/${id}`,
    DELETE_CATEGORY: (id) => `${BASE_URL}/job-preference-category/delete/${id}`,
    REORDER_LIST: `${BASE_URL}/job-preference-category/reorder-list`
};

// Job Preference
export const JOB_PREFERENCE = {
    LIST_CREATE: `${BASE_URL}/job-preference`,
    BULK_UPLOAD: `${BASE_URL}/job-preference/upload-preference`,
    UPDATE_DETAIL_DELETE: id => `${BASE_URL}/job-preference/${id}`,
    EDIT_JOB_PREFERENCE: (id) => `${BASE_URL}/job-preference/update/${id}`,
    LIST_ALL: `${BASE_URL}/job-preference/all`,
    REORDER_LIST: `${BASE_URL}/job-preference/reorder-list`
};

export const API_LEAVE = {
    LIST_CREATE: `${BASE_URL}/company-leave/`,
    GET_UPDATE_DELETE: id => `${BASE_URL}/company-leave/${id}`,
    CHECK_USED_LEAVE: id => `${BASE_URL}/company-leave/check/${id}`, // non active
};

// Platform category
export const PLATFORM_CATEGORY = {
    ALL_LIST: `${BASE_URL}/platform-category/all-list`,
    CREATE: `${BASE_URL}/platform-category/create`,
    EDIT: (platform_category_id) => `${BASE_URL}/platform-category/edit/${platform_category_id}`,
    DELETE: (platform_category_id) => `${BASE_URL}/platform-category/delete/${platform_category_id}`,
};

// Platform version
export const PLATFORM_VERSION = {
    PAGINATE_LIST: `${BASE_URL}/platform-version/paginate-list`,
    LATEST_VERSION: `${BASE_URL}/platform-version/latest-version`,
    CREATE: `${BASE_URL}/platform-version/create`,
    EDIT: (platform_version_id) => `${BASE_URL}/platform-version/edit/${platform_version_id}`,
    DELETE: (platform_version_id) => `${BASE_URL}/platform-version/delete/${platform_version_id}`
};
